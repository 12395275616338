{
  "en": {
    "actualDelay": "Actual Delay",
    "addedItems": "Added items",
    "badFullTruckQuantity": "The order is not respecting the full truck quantity constraint",
    "badMOQOrder": "The order is not respecting the MOQ constraint",
    "badMOQSupply": "The supply is not respecting the MOQ constraint",
    "badOrderFrequency": "The order is not respecting the frequency constraint",
    "cancel": "Cancel",
    "cancelEdit": "cancel edit",
    "deselectAll": "Deselect All",
    "clearSelection": "Clear selection",
    "componentShortageValidationTitle": "Validate order line with component shortage?",
    "componentShortageValidationText": "Some of the components link to this order line is in stock out",
    "componentShortageCancelButton": "Cancel",
    "componentShortageConfirmButton": "Validate order line | Validate order lines",
    "consistsSupplyBufferPolicyProducts": "Consists of products with supply buffer policy",
    "constraint": "Constraint | Constraint | Constraints",
    "constraintFrequency": "Doesn't meet order frequency requirements",
    "constraintFullTruck": "Doesn't meet full truck requirements",
    "constraintMOQ": "Doesn't meet MOQ requirements",
    "constraintsMetMessage": "All constraints are met",
    "constraintsNotMetMessage": "Some of the products don't meet their constraints",
    "customer": {
      "badLotSize": "Lot size not respected",
      "display": "Customer",
      "latestOrderDate": "Send before",
      "orderSent": "Sent",
      "orderValidated": "Validated",
      "partner": "Supplier",
      "validateBtn": "Validate",
      "waitSendOrder": "Waiting to be sent",
      "waitValidateOrder": "Waiting to be validated"
    },
    "validate": "Validate",
    "discard": "Discard",
    "date": "Delivery date",
    "dateRange": "Date range",
    "day": "day | day | days",
    "days": "days",
    "daysOfCoverage": "Stock coverage at delivery",
    "daysShort": "d",
    "delete": "Delete",
    "deleteCurrentOrderSupplyWarningText": "This will delete all the planned supplies inside this order.",
    "deleteCurrentOrderWarningText": "Are you sure you want to remove this order?",
    "deleteOrderError": "Cannot delete the order",
    "deleteOrderText": "Are you sure you want to delete this Order? This will delete all the Planned Supplies inside this Order | Are you sure you want to delete these orders? This will delete all the planned supplies inside these orders",
    "deleteOrderLineText": "Are you sure you want to delete this Order Line? | Are you sure you want to delete these Order Lines?",
    "deleteOrderTitle": "Delete Order | Delete {count} Orders",
    "deleteOrderLineTitle": "Delete Order Line | Delete {count} Order Lines",
    "deleteSelectedOrders": "Delete selected",
    "deleteSuccess": "Order deleted | {count} Orders deleted",
    "orderLineDeleteSuccess": "Order line deleted | {count} Order Lines deleted",
    "deleteSupplyError": "The product could not be deleted correctly",
    "deleteSupplyWarningText": "Are you sure you want to remove this product ?",
    "deleteWarningCancelButton": "Cancel",
    "deleteWarningConfirmButton": "Delete Order | Delete Orders",
    "deleteOrderLineWarningConfirmButton": "Delete Order Line | Delete Order Lines",
    "deleteWarningTitle": "Delete confirmation",
    "deletionMessage": "Deletion is processing",
    "delivered": "Delivered",
    "ifDelayed": "If delayed",
    "delivery": "Delivery",
    "deliveryDate": "Delivery Date",
    "deliveryDateInThePast": "Delivery date from ERP is in past",
    "deliveryDateIsAdjusted": "Flowlity delivery date is adjusted to next possible delivery date",
    "deselectAllPage": "Deselect all on this page",
    "columns": "Columns",
    "resetOrder": "Reset Order",
    "shownInTable": "Shown in Table",
    "hiddenInTable": "Hidden in Table",
    "resetModalCancelButton": "Cancel",
    "resetModalConfirmButton": "Back to Default",
    "resetModalText": "You will lose your current active columns and order",
    "resetModalTitle": "Back to Default Columns Order?",
    "productSite": {
      "subContracted": "With Components"
    },
    "details": {
      "mixedUnits": "mixed units",
      "actualDelay": "Actual Delay",
      "actualDelayTooltipHeader": "Delay vs. initial delivery date",
      "badMoq": "Supplier MOQ is not respected",
      "badFullTruckQuantity": "The order is not respecting the full truck quantity constraint",
      "cancelEdit": "Discard changes",
      "constraintFrequency": "Doesn't meet order frequency requirements",
      "combiningOrders": "Order is updating & combining...",
      "combiningOrdersDesc": "Please don’t worry if this process is taking a while. We just need to update each product carefully",
      "combiningOrdersSuccess": "Order updated & combined",
      "updatingDeliveryDateTitle": "Order is updating...",
      "updatingDeliveryDateDescription": "Please don’t worry if this process is taking a while. We just need to update each product carefully",
      "updatingDeliveryDateSuccess": "Order updated",
      "dates": "Dates",
      "day": "day | day | days",
      "days": "days",
      "delayBelowAvg": "Actual delay is below delay coverage",
      "delayNotCovered": "Actual delay not covered by the applied buffer",
      "shortForDay": "d",
      "deleteWarningTitle": "Delete Order {orderId}",
      "deleteCurrentOrderWarningText": "Are you sure you want to delete this order? This will delete all the planned supplies inside this order.",
      "deleteOrderSuccess": "Order deleted",
      "deleteOrderError": "Cannot delete the current order",
      "deleteWarningConfirmButton": "Delete Order",
      "deleteWarningCancelButton": "Cancel",
      "deleteSuccess": "Order has been deleted",
      "deleting": "Order is being deleted...",
      "deliveryDate": "Delivery Date",
      "editingModalTitle": "Editing",
      "purchaseRequisition": "Purchase Requisition for",
      "purchaseOrder": "Purchase Order for",
      "estDeliveryDate": "Est. Delivery Date",
      "fullTruckQty": "Full truck quantity",
      "groupedBy": "Grouped by tag",
      "initialDeliveryDate": "Initial Delivery Date",
      "latestOrderDate": "Order Placement Deadline",
      "alerts": "Alerts",
      "mergeWarningCancelButton": "Cancel",
      "mergeWarningConfirmButton": "Save & Combine",
      "mergeWarningText": "There is already an order with the same date and supplier. By combining the orders, It will move all order lines of the current order to the other one.",
      "mergeWarningTitle": "Do you want to combine two orders?",
      "moq": "Supplier MOQ",
      "noResultsFound": "Order not found",
      "noResultsFoundSubTitle": "Page with this order ID doesn't exist or was removed",
      "backOrdersList": "Back to Order List",
      "orderConfirm": "Validate Order",
      "discardOrder": "Discard Order",
      "orderConfirmed": "Validated",
      "orderConfirmedSuccess": "Order validated",
      "orderFrequency": "Order frequency",
      "orderExternalId": "External order ID",
      "orderIsUpdating": "Order is updating...",
      "orderQuantityTitle": "Order Quantity",
      "orderUnconfirmSuccess": "Validation discarded",
      "partiallyNecessaryQty": "Not all quantity of product is needed",
      "planned": "Planned",
      "delivered": "Delivered",
      "orderPrice": "Order Price",
      "productDeletionMessage": "Product deletion is processing",
      "productDeletionError": "The product could not be deleted correctly",
      "productTitle": "Products",
      "released": "Released",
      "not_released": "Not released",
      "releaseDate": "Release Date",
      "save": "Save",
      "supplies": {
        "status": "Status",
        "actualDelay": "Actual Delay",
        "averageDelay": "Delay coverage",
        "coverage": "Stock Coverage",
        "atDelivery": "at delivery",
        "current": "current",
        "value": "Value",
        "deleteSupplyAndOrderSuccess": "Product removed and Order deleted | Product removed and Order deleted | Product removed",
        "deleteSupplyAndOrderWarningTitle": "Remove Product & Delete the Order ? | Remove Product & Delete the Order ? | Remove product {supplyId} from order?",
        "deleteSupplyAndOrderWarningText": "This is the only product in the current order, so planned replenishment and this order itself will be deleted | This is the only product in the current order, so planned replenishment and this order itself will be deleted | It will remove planned replenishment as well",
        "deleteSupplyAndOrderWarningConfirmButton": "Remove Product & Delete Order | Remove Product & Delete Order | Remove from order",
        "deleteWarningCancelButton": "Cancel",
        "estDeliveryDate": "Est. Delivery",
        "inputError": "Can’t be empty",
        "lotSize": "Lot Size",
        "moq": "MOQ",
        "name": "Product name",
        "orderUpdatedSuccess": "Order updated",
        "quantity": "Qty",
        "netRequirement": "Net requirement",
        "netRequirementSubTitle": "with product constr.",
        "netRequirementTooltip": "Minimum quantity required, taking product constraints into account. Supplier constraints are excluded",
        "netRequirementNoConstraintsSubTitle": "without any constr.",
        "netRequirementNoConstraintsTooltip": "Minimum quantity required, without taking any constraints into account",
        "refId": "Reference",
        "stock": "Stock",
        "tags": "Tags"
      },
      "supplierConstraints": "Supplier constraints",
      "supplyIsUpdating": "Supply is updating... | Supply is updating... | Supplies are updating...",
      "orderFlowlityId": "Flowlity order ID",
      "orderLineId": "Order line ID",
      "totalQuantity": "Total quantity",
      "uncertainty": {
        "bltPolicy": "Time buffer policy applied",
        "bltPolicyDesc": "Delivery dates are placed in advance of the planning requirements.",
        "bsPolicy": "Stock buffer policy applied",
        "bsPolicyDesc": "Stock min is adjusted to take those delays into account",
        "policyTitle": "There are often delays on this product."
      },
      "unnecessaryQty": "All quantity of product in the order not needed",
      "updateSuppliesQuantitiesSuccess": "Order updated",
      "confirmed": "Validated"
    },
    "discardChanges": "Discard changes",
    "discardOrder": "Discard Order",
    "download_selected": "Download",
    "download": "Download",
    "downloadOrder": "Download Order",
    "edit": "edit",
    "estDeliveryDate": "Est. delivery",
    "netRequirement": "Net requirement",
    "netRequirementQtySubTitle": "with product constr., Qty",
    "netRequirementValueSubTitle": "with product constr., €",
    "netRequirementTooltip": "Minimum quantity required, taking product constraints into account. Supplier constraints are excluded",
    "expectedDelivery": "Expected delivery",
    "fullTruckQty": "Full truck quantity",
    "goodMoq": "Min. order quantity",
    "groupedBy": "Grouped by",
    "in1month": "In 1 month",
    "in2months": "In 2 months",
    "in2Weeks": "In 2 weeks",
    "in3months": "In 3 months",
    "in6months": "In 6 months",
    "in1year": "In 1 year",
    "includesItems": "Number of products",
    "initialDeliveryDate": "Initial Delivery date",
    "inTotal": "In total",
    "issueMOQ": "product have issue with MOQ or lot size | products have issue with MOQ or lot size",
    "items": "product | products",
    "latest_order_date": "Send before",
    "latestOrderDate": "Order Placement Deadline",
    "alerts": "Alerts",
    "leadTime": "Lead time",
    "lotSize": "Lot Size",
    "mergeWarningCancelButton": "Cancel",
    "mergeWarningConfirmButton": "Yes, confirm",
    "mergeWarningText": "An order already exists for this date and supplier. Do you want to combine the two orders?\n This will add the current products and quantities to the order found at this new date.",
    "mergeWarningTitle": "Merge confirmation",
    "moq": "Minimum order quantity",
    "noOrders": "No orders found",
    "noOrdersTags": "No tags found",
    "nOrdersFound": "0 Orders found | 1 Order found | {count} Orders found",
    "noComponentsFound": "No components found",
    "nSubContractedComponentsFound": "0 component found | 1 component found | {count} components found",
    "orderAndSuppliesIssues": "An issue with Product & Supplier Constraints",
    "orderAndSupplyConstraintNotMet": "Some constraints on the order level and product level are not met",
    "orderConfirm": "Validate Orders",
    "orderLineConfirm": "Validate Order Lines",
    "orderConfirmed": "Validated",
    "orderConfirmError": "Something went wrong during the orders validation",
    "orderConfirmSuccess": "Order validated | {count} Orders validated",
    "orderLineConfirmSuccess": "Order line validated | {count} Order lines validated",
    "orderSendError": "Something went wrong during the orders sending",
    "orderSendSuccess": "Order sent | {count} Orders sent",
    "orderConstraintNotMet": "Some constraints on the order level are not met",
    "orderConstraints": "Order constraints",
    "orderDetailMessageTitle": "Messages",
    "orderDiscard": "Discard",
    "orderDiscardSuccess": "Order validation discarded | {count} Orders validation discarded",
    "orderFrequency": "Order frequency",
    "orderExternalIdSubtitle": "external",
    "orderIsConfirming": "Order is validating... | Orders are validating...",
    "orderLineIsConfirming": "Order line is validating... | Order lines are validating...",
    "orderIsDiscarding": "Order is discarding... | Orders are discarding...",
    "orderLineIsDiscarding": "Order line is discarding... | Order lines are discarding...",
    "orderIsSending": "Order is sending... | Orders are sending...",
    "orderIsLoading": "Order is loading...",
    "orderIssue": "Order issue",
    "orderIssues": "An issue with Supplier Constraints",
    "orderIsUpdating": "Order is updating...",
    "orderModalAddProductsButton": "Add products",
    "orderModalTitle": "for",
    "orders": "Orders | Order | Orders",
    "Orders": "Orders | Order | Orders",
    "OrderLines": "Order Lines | Order Line | Order Lines",
    "orderTitle": "Orders",
    "orderTitleDetail": "Order details",
    "planned": "Planned",
    "planning": {
      "backToOrder": "BACK TO ORDERS",
      "emptyTitle": "",
      "emptySubtitle1": "",
      "emptySubtitle2": "",
      "expectedDelivery": "Expected delivery",
      "lotSize": "Lot Size",
      "moq": "MOQ",
      "orderPlanningTitle": "Product Stock Planning",
      "stackChoice": "stack",
      "orderDeleted": "Replenishment removed and Order deleted",
      "open": "Open planning"
    },
    "price": "Price",
    "orderPrice": "Order Price",
    "product": "Product",
    "productIssue": "Product issue",
    "productIssues": "Product issues",
    "products": "Products",
    "productsCount": "Order lines",
    "productsHaveIssues": "0 | 1 product has issue with MOQ/Lot Size | {count} products have issue with MOQ/Lot Size",
    "released": "Released",
    "not_released": "Not released",
    "quantity": "Quantity",
    "reference": "Reference",
    "releaseDate": "Release Date",
    "reset": "Reset",
    "salesOrders": "Sales orders",
    "save": "Save",
    "search": "Search by order ID, supplier or product",
    "secondaryUnit": "Secondary unit",
    "select_all": "Select all",
    "selectAllPage": "Select all on this page",
    "selected_orders": "Selected orders | Selected orders",
    "selected": "Selected",
    "selectedTags": "1 tag selected | {count} tags selected",
    "sftp_exported": "Exported to SFTP",
    "sftp_selected": "Export to SFTP",
    "sftpError": "Error exporting orders, please contact flowlity",
    "sftpSuccess": "1 Order exported to SFTP | {count} Orders exported to SFTP",
    "sftpReExportTitle": "Re-export to SFTP orders?",
    "sftpReExportDescription": "Selected orders already exported to SFTP, re-exporting them will create duplicates",
    "sftpReExportBtn": "Re-export to SFTP",
    "sftpWhatsExportTitle": "What orders to export?",
    "sftpWhatsExportDescription": "Some orders already exported to SFTP, re-exporting them will create duplicates",
    "sftpWhatsExportAllBtn": "Export all",
    "sftpWhatsExportNewBtn": "Export new orders",
    "ship_before": "Ship before",
    "subContracted": {
      "nextDeliveryDate": "Next delivery date",
      "nextDeliveryQty": "Next delivery q-ty",
      "productExternalId": "Reference",
      "productName": "Product name",
      "quantity": "Quantity",
      "stock": "Stock qty",
      "current": "current",
      "required": "required",
      "unitIssues": "Units issue",
      "unitsIssueTooltip": "There is a mismatch of units between BOM and Flowlity app"
    },
    "supplier": {
      "badLotSize": "Lot size not respected",
      "display": "Supplier",
      "latestOrderDate": "Ship before",
      "leadTimeNotSet": "Delivery lead time is not defined. Set it.",
      "orderSent": "Shipped",
      "orderValidated": "Accepted",
      "partner": "Customer",
      "validateBtn": "Accept",
      "waitSendOrder": "Waiting to be shipped",
      "waitValidateOrder": "Waiting to be accepted"
    },
    "suppliers": "Suppliers | Supplier | Suppliers",
    "supplyConstraintNotMet": "Some products don't meet their constraints",
    "tags": "Tag | Tags",
    "orderFlowlityIdSubtitle": "Flowlity order",
    "id": "ID",
    "orderLineSubtitle": "Flowlity order line",
    "firmOrders": "Firm supply orders",
    "firmOrdersSubtitle": "Qty",
    "thisWeek": "This week",
    "totalQuantity": "Total quantity",
    "unnecessarySupply": "are necessary | is necessary | are necessary",
    "updateError": "Order(s) could not be updated correctly",
    "updateSuccess": "Successfully updated order(s)",
    "confirmed": "Validated",
    "value": "Value",
    "volume": "Quantity",
    "displayedOnStatuses": "Displayed on statuses:",
    "orderLine": "Order Lines",
    "orderLines": {
      "confirmed": "Validated",
      "create": "Create",
      "createdMessage": "Order line has been created",
      "createOrderLine": "Create Order line",
      "delivered": "Delivered",
      "deliveryDate": "Delivery Date",
      "discardChangesBtn": "Discard changes",
      "discardChangesDescription": "Your will loose all changes that were made",
      "discardChangesTitle": "Discard changes?",
      "enterQty": "Enter quantity",
      "errorMessage": "Error occurred while creating the order line",
      "fixedFromPlanning": "Fixed from planning",
      "not_released": "Not released",
      "planned": "Planned",
      "product": "Product",
      "productExternalId": "Reference",
      "productName": "Product Name",
      "productDescription": "Product Description",
      "quantity": "Quantity",
      "released": "Released",
      "selectDeliveryDate": "Select date",
      "selectProduct": "Select product",
      "selectSupplier": "Select supplier",
      "subComponentsNoResultsFound": "No component data",
      "subComponentsNoResultSubTitle": "No valid component data could be retrieved from BOM data",
      "supplier": "Supplier",
      "componentShortage": "Component shortage",
      "productConstraint": "Product constraint",
      "supplierConstraint": "Supplier constraint",
      "allocationNeeded": "Allocation needed"
    },
    "alertsDropdown": {
      "component_shortage": "Component shortage",
      "product_constraint": "Product constraints issue",
      "supplier_constraint": "Supplier constraints issue",
      "allocation_needed": "Allocation needed"
    },
    "computing": "Computing"
  },
  "fr": {
    "actualDelay": "Retard",
    "addedItems": "Produits ajoutés",
    "badFullTruckQuantity": "La contrainte de camion plein n'est pas respectée",
    "badMOQOrder": "Le minimum de commande n'est pas respecté",
    "badMOQSupply": "La quantité minimum n'est pas respectée",
    "badOrderFrequency": "La contrainte de fréquence de commande n'est pas respectée",
    "cancel": "Annuler",
    "cancelEdit": "annuler l'édition",
    "deselectAll": "Tout désélectionner",
    "clearSelection": "Tout désélectionner",
    "componentShortageValidationTitle": "Valider cette ligne de commande avec des ruptures de composants? | Valider ces lignes de commandes avec des ruptures de composants?",
    "componentShortageValidationText": "Des componsants appartenant à cette ligne de commandes sont en rupture  | Des componsants appartenant à ces lignes de commandes sont en rupture",
    "componentShortageCancelButton": "Annuler",
    "componentShortageConfirmButton": "Valider cette ligne de commandes | Valider ces lignes de commandes",
    "consistsSupplyBufferPolicyProducts": "Produits avec politique de buffer supply",
    "constraint": "Contrainte | Contrainte | Contraintes",
    "constraintFrequency": "Ne respecte pas la contrainte de fréquence de commande",
    "constraintFullTruck": "Ne respecte pas la contrainte de camion plein",
    "constraintMOQ": "Ne respecte pas la contrainte de MOQ",
    "constraintsMetMessage": "Toutes les contraintes sont respectées",
    "constraintsNotMetMessage": "Certains produits ne respectent pas leurs contraintes",
    "customer": {
      "badLotSize": "La taille du lot n'est pas respectée",
      "display": "Client",
      "latestOrderDate": "Expédier avant",
      "orderSent": "Expédié",
      "orderValidated": "Validé",
      "partner": "Fournisseur",
      "validateBtn": "Valider",
      "waitSendOrder": "En attente d'expédition",
      "waitValidateOrder": "En attente de validation"
    },
    "validate": "Valider",
    "discard": "Annuler",
    "date": "Date de livraison",
    "dateRange": "Période",
    "day": "jour | jour | jours",
    "days": "jours",
    "daysOfCoverage": "Couverture de stock à réception",
    "daysShort": "j",
    "delete": "Supprimer",
    "deleteCurrentOrderSupplyWarningText": "Cela va supprimer tous les approvisionnements liés à cette commande",
    "deleteCurrentOrderWarningText": "Voulez-vous supprimer cette commande ?",
    "deleteOrderError": "Suppression impossible",
    "deleteSelectedOrders": "Supprimer la sélection",
    "deleteSupplyError": "Une erreur est survenue lors de la suppression du produit",
    "deleteSupplyWarningText": "Êtes-vous sûr de vouloir supprimer ce produit ?",
    "deleteOrderTitle": "Supprimer la commande | Supprimer {count} commandes",
    "deleteOrderLineTitle": "Supprimer la ligne de commande | Supprimer {count} lignes de commandes",
    "deleteOrderText": "Êtes-vous sûr de vouloir supprimer cette commande ? Toutes les lignes de cette commande seront supprimées. | Êtes-vous sûr de vouloir supprimer ces commandes ? Toutes les lignes de ces commandes seront supprimées.",
    "deleteOrderLineText": "Êtes-vous sûr de vouloir supprimer cette ligne de commande ? | Êtes-vous sûr de vouloir supprimer ces lignes de commandes ?",
    "deleteWarningCancelButton": "Annuler",
    "deleteWarningConfirmButton": "Supprimer la commande | Supprimer les commandes",
    "deleteOrderLineWarningConfirmButton": "Supprimer la ligne de commande | Supprimer les lignes de commandes",
    "deleteSuccess": "Commande supprimée | {count} Commandes supprimées",
    "orderLineDeleteSuccess": "Ligne de commande supprimée | {count} lignes de commandes supprimées",
    "deleteWarningTitle": "Supprimer la commande",
    "orderConfirmSuccess": "Commande validée | {count} commandes validées",
    "orderLineConfirmSuccess": "Ligne de commande validée | {count} Lignes de commande validées",
    "orderDiscardSuccess": "Validation de commande annulée | {count} validations de commande annulées",
    "deletionMessage": "La suppression est en cours",
    "delivered": "Livrées",
    "ifDelayed": "Si en retard ",
    "delivery": "Livraison le",
    "deliveryDate": "Date de livraison",
    "deliveryDateInThePast": "La date de livraison de l'ERP est dans le passé",
    "deliveryDateIsAdjusted": "La date de livraison Flowlity a été replacée à la première date possible",
    "deselectAllPage": "Désélectionner toute la page",
    "columns": "Colonnes",
    "resetOrder": "Réinitialiser l'ordre",
    "shownInTable": "Visible dans la table",
    "hiddenInTable": "Non-visible dans la table",
    "resetModalCancelButton": "Annuler",
    "resetModalConfirmButton": "Réinitialiser",
    "resetModalText": "Vous perdrez les paramètres actuels concernant l'ordre et l'activation des colonnes",
    "resetModalTitle": "Réinitialiser l'affichage par défaut ?",
    "details": {
      "mixedUnits": "unités mixtes",
      "actualDelay": "Retard actuel",
      "actualDelayTooltipHeader": "Retard sur date initiale de livraison",
      "badMoq": "Le minimum de commande n'est pas respecté",
      "badFullTruckQuantity": "La contrainte de camion plein n'est pas respectée",
      "cancelEdit": "Annuler les modifications",
      "constraintFrequency": "Ne respecte pas la contrainte de fréquence de commande",
      "combiningOrders": "Mise à jour de la commande",
      "combiningOrdersDesc": "Ne vous inquiétez pas si ce processus prend du temps. Nous avons juste besoin de mettre à jour soigneusement chaque produit.",
      "combiningOrdersSuccess": "Commande mise à jour & combinée",
      "updatingDeliveryDateTitle": "Mise à jour de la commande...",
      "updatingDeliveryDateDescription": "Ne vous inquiétez pas si ce processus prend du temps. Nous avons juste besoin de mettre à jour soigneusement chaque produit.",
      "updatingDeliveryDateSuccess": "Commande mise à jour",
      "dates": "Dates",
      "day": "jour | jour | jours",
      "days": "jours",
      "delayBelowAvg": "En dessous du retard couvert",
      "delayNotCovered": "Retard non couvert par le stock sécurité sur délai d’approvisionnement",
      "shortForDay": "j",
      "deleteWarningTitle": "Supprimer la commande {orderId}",
      "deleteCurrentOrderWarningText": "Souhaitez-vous vraiment supprimer cette commande ? Cela supprimera également les quantités des produits liés à cette commande.",
      "deleteOrderSuccess": "Commande supprimée",
      "deleteOrderError": "Suppression impossible",
      "deleteWarningConfirmButton": "Supprimer",
      "deleteWarningCancelButton": "Annuler",
      "deleteSuccess": "Commande supprimée",
      "deleting": "La suppression est en cours",
      "deliveryDate": "Date de livraison",
      "editingModalTitle": "Édition",
      "purchaseRequisition": "Demande d'achat pour",
      "purchaseOrder": "Commande d'achat pour",
      "estDeliveryDate": "Date de livraison est.",
      "fullTruckQty": "Camion plein",
      "groupedBy": "Groupé par tag",
      "initialDeliveryDate": "Date de livraison initiale",
      "latestOrderDate": "Date d'échéance",
      "alerts": "Alertes",
      "mergeWarningCancelButton": "Annuler",
      "mergeWarningConfirmButton": "Confirmer et combiner",
      "mergeWarningText": "Une commande existe déjà pour cette date et ce fournisseur. Voulez-vous combiner les deux commandes?\n Ceci aura pour effet de combiner les produits des deux commandes à cette nouvelle date.",
      "mergeWarningTitle": "Confirmation de combinaison",
      "moq": "MOQ fournisseur",
      "noResultsFound": "Commande non trouvée",
      "noResultsFoundSubTitle": "La page correspondant à cette commande n'existe pas ou à été supprimée",
      "backOrdersList": "Retour à la liste des commandes",
      "orderConfirm": "Valider la commande",
      "discardOrder": "Annuler la commande",
      "orderConfirmed": "Validée",
      "orderConfirmedSuccess": "Commande validée",
      "orderFrequency": "Fréquence de commande",
      "orderExternalId": "ID externe",
      "orderIsUpdating": "Mise à jour de la commande...",
      "orderQuantityTitle": "Quantités",
      "orderUnconfirmSuccess": "Validation annulée",
      "partiallyNecessaryQty": "Certaines quantités ne sont pas nécessaires",
      "planned": "Commande planifiée",
      "delivered": "Livrée",
      "orderPrice": "Prix commande",
      "productTitle": "Produits",
      "productDeletionMessage": "La suppression est en cours",
      "productDeletionError": "Une erreur est survenue lors de la suppression du produit",
      "released": "Fermes",
      "not_released": "Non fermes",
      "releaseDate": "Date de commande",
      "save": "Enregistrer",
      "supplies": {
        "status": "Statut",
        "actualDelay": "Retard Actuel",
        "averageDelay": "Retard Couvert",
        "coverage": "Couverture de stock",
        "atDelivery": "à la livraison",
        "current": "actuel",
        "value": "Valeur",
        "deleteSupplyAndOrderSuccess": "La commande a été supprimée avec succès | La commande a été supprimée avec succès | Le produit a été supprimé avec succès",
        "deleteSupplyAndOrderWarningTitle": "Supprimer le produit et la commande ? | Supprimer le produit et la commande ? | Supprimer de la commande ?",
        "deleteSupplyAndOrderWarningText": "Ce produit est le seul de la commande, le besoin planning et la commande seront supprimés | Ce produit est le seul de la commande, le besoin planning et la commande seront supprimés | Les besoins du planning seront aussi supprimés",
        "deleteSupplyAndOrderWarningConfirmButton": "Supprimer produit et commande | Supprimer produit et commande | Supprimer de la commande",
        "deleteWarningCancelButton": "Annuler",
        "estDeliveryDate": "Livraison Est.",
        "inputError": "Ne peut être vide",
        "lotSize": "Taille de lot",
        "moq": "MOQ",
        "orderUpdatedSuccess": "Commande mise à jour",
        "quantity": "Qté",
        "netRequirement": "Besoin net",
        "netRequirementSubTitle": "incl. contr. produits",
        "netRequirementTooltip": "Besoin minimum, prenant en compte les contraintes liées au produit. Les contraintes au niveau fournisseur ne sont pas prises en compte",
        "netRequirementNoConstraintsSubTitle": "sans contraintes",
        "netRequirementNoConstraintsTooltip": "Besoin minimum, sans prendre en compte les contraintes",
        "name": "Nom du produit",
        "refId": "Référence",
        "stock": "Stock",
        "tags": "Tags"
      },
      "supplierConstraints": "Contraintes fournisseur",
      "supplyIsUpdating": "Le produit est mis à jour... | Le produit est mis à jour... | Les produits sont mis à jour...",
      "orderFlowlityId": "ID de la commande Flowlity",
      "orderLineId": "ID de la ligne de commande",
      "totalQuantity": "Quantité totale",
      "confirmed": "Validé",
      "uncertainty": {
        "bltPolicy": "Politique de buffer temps time appliquée",
        "bsPolicy": "Politique de buffer stock appliquée",
        "policyTitle": "Ce produit est régulièrement livré en retard.",
        "bltPolicyDesc": "Les dates de livraison sont positionnées en avance des besoins planning.",
        "bsPolicyDesc": "Le stock minimum est ajusté pour prendre en compte ces retards."
      },
      "unnecessaryQty": "Aucune quantité n'est nécessaire",
      "updateSuppliesQuantitiesSuccess": "Commande mise à jour"
    },
    "discardChanges": "Annuler les modifications",
    "discardOrder": "Annuler la validation",
    "download_selected": "Télécharger",
    "download": "Télécharger",
    "downloadOrder": "Télécharger la commande",
    "edit": "éditer",
    "estDeliveryDate": "Livraison estimée",
    "netRequirement": "Besoin net",
    "netRequirementQtySubTitle": "incl. contr. produits, Qte",
    "netRequirementValueSubTitle": "incl. contr. produits, €",
    "netRequirementTooltip": "Besoin minimum, prenant en compte les contraintes liées au produit. Les contraintes au niveau fournisseur ne sont pas prises en compte",
    "expectedDelivery": "Livraison attendue",
    "fullTruckQty": "Camion plein",
    "goodMoq": "Minimum de commande",
    "groupedBy": "Groupé par",
    "in1month": "Dans 1 mois",
    "in2months": "Dans 2 mois",
    "in2Weeks": "Dans 2 semaines",
    "in3months": "Dans 3 mois",
    "in6months": "Dans 6 mois",
    "in1year": "Dans 1 an",
    "includesItems": "Nombre de produits",
    "initialDeliveryDate": "Date de livraison initiale",
    "inTotal": "Total",
    "issueMOQ": " produit a un problème de MOQ ou de taille de lot | produits ont un problème de MOQ ou de taille de lot",
    "items": "produit | produits",
    "lastOrderDate": "Date de la commande",
    "latest_order_date": "Passer commande avant le",
    "latestOrderDate": "Date d'échéance",
    "alerts": "Alertes",
    "leadTime": "Délai de livraison",
    "lotSize": "Taille de lot",
    "mergeWarningCancelButton": "Annuler",
    "mergeWarningConfirmButton": "Je confirme",
    "mergeWarningText": "Une commande existe déjà pour cette date et ce fournisseur. Voulez-vous combiner les deux commandes?\n Ceci aura pour effet de combiner les produits des deux commandes à cette nouvelle date.",
    "mergeWarningTitle": "Confirmation de combinaison",
    "moq": "Minimum de commande",
    "noOrders": "Pas de commande trouvée",
    "noOrdersTags": "Aucun tag trouvé",
    "nOrdersFound": "0 commande trouvées | 1 commande trouvées | {count} commandes trouvées",
    "noComponentsFound": "Aucun composant  trouvé",
    "nSubContractedComponentsFound": "0 composant trouvé | 1 composant trouvé | {count} composants trouvés",
    "noTimeRestriction": "Pas de limitation",
    "orderAndSuppliesIssues": "Erreur sur contraintes produit et fournisseur",
    "orderAndSupplyConstraintNotMet": "Certaines contraintes au niveau commande et au niveau produit ne sont pas respectées",
    "orderConfirm": "Valider les commandes",
    "orderLineConfirm": "Valider les lignes de commande",
    "orderConfirmed": "Validée",
    "orderConfirmError": "Une erreur est survenue lors de la validation des commandes",
    "orderConstraintNotMet": "Certaines contraintes au niveau commande ne sont pas respectées",
    "orderConstraints": "Contraintes de commande",
    "orderDetailMessageTitle": "Messages",
    "orderDiscard": "Annuler",
    "orderFrequency": "Fréquence de commande",
    "orderExternalIdSubtitle": "externe",
    "orderIsConfirming": "Commande en cours de validation... | Commandes en cours de validation...",
    "orderLineIsConfirming": "Ligne de commande en cours de validation... | Lignes de commande en cours de validation...",
    "orderIsDiscarding": "Commande en cours d'annulation... | Commandes en cours d'annulation...",
    "orderLineIsDiscarding": "Ligne de commande en cours d'annulation... | Lignes de commande en cours d'annulation...",
    "orderIsSending": "Commande en cours de téléchargement... | Commandes en cours de téléchargement...",
    "orderSendError": "Erreur lors du téléchargement des commandes, veuillez contacter Flowlity",
    "orderSendSuccess": "1 commande téléchargée | {count} commandes téléchargées",
    "orderIsLoading": "Chargement de la commande...",
    "orderIssue": "Lié à la commande",
    "orderIssues": "Erreur sur contraintes fournisseur",
    "orderIsUpdating": "La commande est mise à jour...",
    "orderModalAddProductsButton": "Ajouter des produits",
    "orderModalTitle": "Commande pour",
    "orders": "commande | commande | commandes",
    "Orders": "Commande | Commande | Commandes",
    "OrderLines": "Ligne de commande | Ligne de commande | Lignes de commande",
    "orderTitle": "Commandes",
    "orderTitleDetail": "Détails de la commande",
    "planned": "Planifiées",
    "planning": {
      "backToOrder": "RETOUR",
      "emptyTitle": "",
      "emptySubtitle1": "",
      "emptySubtitle2": "",
      "expectedDelivery": "Livraison attendue",
      "lotSize": "Taille de lot",
      "moq": "Minimum de commande",
      "orderPlanningTitle": "Planning",
      "stackChoice": "stacké",
      "orderDeleted": "commande supprimée",
      "open": "Ouvrir le planning"
    },
    "price": "Prix",
    "orderPrice": "Prix commande",
    "product": "Produit",
    "productDeletionMessage": "La suppression est en cours",
    "productIssue": "Lié aux produits",
    "productIssues": "Lié aux produits",
    "products": "Produits",
    "productsCount": "Lignes de commande",
    "productsHaveIssues": "0 | 1 produit en erreur : Minimum de commande / Taille de lot | {count} produits en erreur : Minimum de commande / Taille de lot",
    "productSite": {
      "subContracted": "Avec Composants"
    },
    "released": "Fermes",
    "not_released": "Non fermes",
    "quantity": "Qté",
    "reference": "Référence",
    "releaseDate": "Date de commande",
    "reset": "Réinitialiser",
    "salesOrders": "Commandes de vente",
    "save": "Enregistrer",
    "search": "Chercher par ID, fournisseur ou produit",
    "secondaryUnit": "Unité secondaire",
    "select_all": "Tout sélectionner",
    "selectAllPage": "Sélectionner toute la page",
    "selected_orders": "Commande sélectionnée | Commandes sélectionnées",
    "selected": "sélectionnée | sélectionnée | sélectionnées",
    "selectedTags": "1 tag sélectionné | {count} tags sélectionnés",
    "sftp_exported": "Exportée via SFTP",
    "sftp_selected": "Exporter via SFTP",
    "sftpError": "Erreur lors de l'exportation des commandes, veuillez contacter flowlity",
    "sftpSuccess": "1 commande exportée vers SFTP | {count} commandes exportées vers SFTP",
    "sftpReExportTitle": "Réexporter les commandes vers SFTP ?",
    "sftpReExportDescription": "Les commandes sélectionnées ont déjà été exportées vers SFTP, leurs réexportations créeront des doublons",
    "sftpReExportBtn": "Réexporter vers SFTP",
    "sftpWhatsExportTitle": "Quelles commandes exporter ?",
    "sftpWhatsExportDescription": "Certaines commandes ont déjà été exportées vers SFTP, leurs réexportations créeront des doublons",
    "sftpWhatsExportAllBtn": "Exporter toutes",
    "sftpWhatsExportNewBtn": "Exporter les nouvelles commandes",
    "ship_before": "Expédier le",
    "subContracted": {
      "unitIssues": "Erreur des unités",
      "unitsIssueTooltip": "Aucune correspondance entre les unités de BOM et Flowlity",
      "productExternalId": "Référence",
      "productName": "Désignation produit",
      "quantity": "Quantité",
      "required": "requise",
      "stock": "Stock qté",
      "current": "actuel",
      "nextDeliveryDate": "Prochaine livraison",
      "nextDeliveryQty": "Quantité à livrer"
    },
    "supplier": {
      "badLotSize": "La taille du lot n'est pas respectée",
      "display": "Fournisseur",
      "latestOrderDate": "Expédier avant",
      "leadTimeNotSet": "Le délai de livraison n'est pas défini. Configurer.",
      "orderSent": "Expédié",
      "orderValidated": "Accepté",
      "partner": "Client",
      "validateBtn": "Accepter",
      "waitSendOrder": "En attente d'expédition",
      "waitValidateOrder": "En attente de validation"
    },
    "suppliers": "Fournisseur | Fournisseur | Fournisseurs",
    "supplyConstraintNotMet": "Certains produits ne respectent pas leurs contraintes",
    "tags": "Tag | Tags",
    "orderFlowlityIdSubtitle": "commande Flowlity",
    "id": "ID",
    "orderLineSubtitle": "ligne de commande",
    "firmOrders": "Commandes d'appro. fermes",
    "firmOrdersSubtitle": "Qté",
    "thisWeek": "Cette semaine",
    "totalQuantity": "Quantité totale",
    "unnecessarySupply": "est nécessaire | est nécessaire | sont nécessaires",
    "updateError": "Un problème a été rencontré pendant la mise à jour des commandes",
    "updateSuccess": "Commande(s) correctement mise(s) à jour",
    "confirmed": "Validées",
    "value": "Valeur",
    "volume": "Quantité",
    "displayedOnStatuses": "Affiché sur les statuts:",
    "orderLine": "lignes de commande",
    "orderLines": {
      "confirmed": "Validée",
      "create": "Créer",
      "createdMessage": "La ligne de commande a été créée",
      "createOrderLine": "Créer une ligne de commande",
      "delivered": "Livrées",
      "deliveryDate": "Date de livraison",
      "discardChangesBtn": "Annuler les modifications",
      "discardChangesDescription": "Vous perdrez toutes les modifications effectuées",
      "discardChangesTitle": "Annuler les modifications ?",
      "enterQty": "Entrez la quantité",
      "errorMessage": "Une erreur s'est produite lors de la création de la ligne de commande",
      "fixedFromPlanning": "Fixé depuis le planning",
      "not_released": "Non fermes",
      "planned": "Planifiées",
      "product": "Produit",
      "productExternalId": "Réference",
      "productName": "Désignation produit",
      "productDescription": "Description produit",
      "quantity": "Quantité",
      "released": "Fermes",
      "selectDeliveryDate": "Sélectionnez la date",
      "selectProduct": "Sélectionnez le produit",
      "selectSupplier": "Sélectionnez le fournisseur",
      "subComponentsNoResultsFound": "Aucune donnée de composants",
      "subComponentsNoResultSubTitle": "Aucun composant n'a été trouvé dans les données de BOM",
      "supplier": "Fournisseur",
      "componentShortage": "Rupture de composants",
      "productConstraint": "Contrainte produit",
      "supplierConstraint": "Contrainte fournisseur",
      "allocationNeeded": "Allocation nécessaire"
    },
    "alertsDropdown": {
      "component_shortage": "Rupture de composants",
      "product_constraint": "Problème de contraintes du produit",
      "supplier_constraint": "Problème de contraintes du fournisseur",
      "allocation_needed": "Allocation nécessaire"
    },
    "computing": "Calcul"
  },
  "ru": {
    "actualDelay": "Фактическая задержка",
    "addedItems": "Добавленные элементы",
    "badFullTruckQuantity": "Заказ не соответствует ограничению на количество для полного грузовика",
    "badMOQOrder": "Заказ не соответствует ограничению MOQ",
    "badMOQSupply": "Поставка не соответствует ограничению MOQ",
    "badOrderFrequency": "Заказ не соответствует частотному ограничению",
    "cancel": "Отмена",
    "cancelEdit": "отменить редактирование",
    "deselectAll": "Снять все выделения",
    "clearSelection": "Очистить выбор",
    "componentShortageValidationTitle": "Подтвердить строку заказа с нехваткой компонентов?",
    "componentShortageValidationText": "Некоторые из компонентов, связанных с этой строкой заказа, отсутствуют на складе",
    "componentShortageCancelButton": "Отмена",
    "componentShortageConfirmButton": "Подтвердить строку заказа | Подтвердить строки заказа",
    "consistsSupplyBufferPolicyProducts": "Состоит из продуктов с политикой буфера поставок",
    "constraint": "Ограничение | Ограничение | Ограничения",
    "constraintFrequency": "Не соответствует требованиям частоты заказов",
    "constraintFullTruck": "Не соответствует требованиям полного грузовика",
    "constraintMOQ": "Не соответствует требованиям MOQ",
    "constraintsMetMessage": "Все ограничения выполнены",
    "constraintsNotMetMessage": "Некоторые продукты не соответствуют своим ограничениям",
    "customer": {
      "badLotSize": "Размер партии не соблюден",
      "display": "Клиент",
      "latestOrderDate": "Отправить до",
      "orderSent": "Отправлено",
      "orderValidated": "Подтверждено",
      "partner": "Поставщик",
      "validateBtn": "Подтвердить",
      "waitSendOrder": "Ожидает отправки",
      "waitValidateOrder": "Ожидает подтверждения"
    },
    "validate": "Подтвердить",
    "discard": "Отменить",
    "date": "Дата доставки",
    "dateRange": "Диапазон дат",
    "day": "день | день | дней",
    "days": "дней",
    "daysOfCoverage": "Покрытие запасов при доставке",
    "daysShort": "д",
    "delete": "Удалить",
    "deleteCurrentOrderSupplyWarningText": "Это удалит все запланированные поставки в этом заказе.",
    "deleteCurrentOrderWarningText": "Вы уверены, что хотите удалить этот заказ?",
    "deleteOrderError": "Не удалось удалить заказ",
    "deleteOrderText": "Вы уверены, что хотите удалить этот заказ? Это удалит все запланированные поставки в этом заказе | Вы уверены, что хотите удалить эти заказы? Это удалит все запланированные поставки в этих заказах",
    "deleteOrderLineText": "Вы уверены, что хотите удалить эту строку заказа? | Вы уверены, что хотите удалить эти строки заказа?",
    "deleteOrderTitle": "Удалить заказ | Удалить {count} заказов",
    "deleteOrderLineTitle": "Удалить строку заказа | Удалить {count} строк заказа",
    "deleteSelectedOrders": "Удалить выбранное",
    "deleteSuccess": "Заказ удален | {count} заказов удалено",
    "orderLineDeleteSuccess": "Строка заказа удалена | {count} строк заказа удалено",
    "deleteSupplyError": "Не удалось корректно удалить продукт",
    "deleteSupplyWarningText": "Вы уверены, что хотите удалить этот продукт?",
    "deleteWarningCancelButton": "Отмена",
    "deleteWarningConfirmButton": "Удалить заказ | Удалить заказы",
    "deleteOrderLineWarningConfirmButton": "Удалить строку заказа | Удалить строки заказа",
    "deleteWarningTitle": "Подтверждение удаления",
    "deletionMessage": "Удаление в процессе",
    "delivered": "Доставлено",
    "ifDelayed": "Если задерживается",
    "delivery": "Доставка",
    "deliveryDate": "Дата доставки",
    "deliveryDateInThePast": "Дата доставки из ERP в прошлом",
    "deliveryDateIsAdjusted": "Дата доставки Flowlity скорректирована на следующую возможную дату доставки",
    "deselectAllPage": "Снять все выделения на этой странице",
    "columns": "Столбцы",
    "resetOrder": "Сбросить порядок",
    "shownInTable": "Показано в таблице",
    "hiddenInTable": "Скрыто в таблице",
    "resetModalCancelButton": "Отмена",
    "resetModalConfirmButton": "Вернуться к настройкам по умолчанию",
    "resetModalText": "Вы потеряете текущие активные столбцы и порядок",
    "resetModalTitle": "Вернуться к настройкам столбцов по умолчанию?",
    "productSite": {
      "subContracted": "С компонентами"
    },
    "details": {
      "mixedUnits": "смешанные единицы",
      "actualDelay": "Фактическая задержка",
      "actualDelayTooltipHeader": "Задержка по сравнению с первоначальной датой доставки",
      "badMoq": "MOQ поставщика не соблюден",
      "badFullTruckQuantity": "Заказ не соответствует ограничению на количество для полного грузовика",
      "cancelEdit": "Отменить изменения",
      "constraintFrequency": "Не соответствует требованиям частоты заказов",
      "combiningOrders": "Заказ обновляется и объединяется...",
      "combiningOrdersDesc": "Не волнуйтесь, если этот процесс займет некоторое время. Нам просто нужно тщательно обновить каждый продукт",
      "combiningOrdersSuccess": "Заказ обновлен и объединен",
      "updatingDeliveryDateTitle": "Заказ обновляется...",
      "updatingDeliveryDateDescription": "Не волнуйтесь, если этот процесс займет некоторое время. Нам просто нужно тщательно обновить каждый продукт",
      "updatingDeliveryDateSuccess": "Заказ обновлен",
      "dates": "Даты",
      "day": "день | день | дней",
      "days": "дней",
      "delayBelowAvg": "Фактическая задержка ниже покрытия задержки",
      "delayNotCovered": "Фактическая задержка не покрыта применяемым буфером",
      "shortForDay": "д",
      "deleteWarningTitle": "Удалить заказ {orderId}",
      "deleteCurrentOrderWarningText": "Вы уверены, что хотите удалить этот заказ? Это удалит все запланированные поставки в этом заказе.",
      "deleteOrderSuccess": "Заказ удален",
      "deleteOrderError": "Не удалось удалить текущий заказ",
      "deleteWarningConfirmButton": "Удалить заказ",
      "deleteWarningCancelButton": "Отмена",
      "deleteSuccess": "Заказ был удален",
      "deleting": "Заказ удаляется...",
      "deliveryDate": "Дата доставки",
      "editingModalTitle": "Редактирование",
      "purchaseRequisition": "Запрос на покупку для",
      "purchaseOrder": "Заказ на покупку для",
      "estDeliveryDate": "Ожидаемая дата доставки",
      "fullTruckQty": "Количество для полного грузовика",
      "groupedBy": "Сгруппировано по тегу",
      "initialDeliveryDate": "Первоначальная дата доставки",
      "latestOrderDate": "Крайний срок размещения заказа",
      "alerts": "Оповещения",
      "mergeWarningCancelButton": "Отмена",
      "mergeWarningConfirmButton": "Сохранить и объединить",
      "mergeWarningText": "Уже существует заказ с той же датой и поставщиком. Объединение заказов переместит все строки текущего заказа в другой.",
      "mergeWarningTitle": "Хотите объединить два заказа?",
      "moq": "MOQ поставщика",
      "noResultsFound": "Заказ не найден",
      "noResultsFoundSubTitle": "Страница с этим идентификатором заказа не существует или была удалена",
      "backOrdersList": "Вернуться к списку заказов",
      "orderConfirm": "Подтвердить заказ",
      "discardOrder": "Отменить заказ",
      "orderConfirmed": "Подтверждено",
      "orderConfirmedSuccess": "Заказ подтвержден",
      "orderFrequency": "Частота заказов",
      "orderExternalId": "Внешний идентификатор заказа",
      "orderIsUpdating": "Заказ обновляется...",
      "orderQuantityTitle": "Количество заказа",
      "orderUnconfirmSuccess": "Подтверждение отменено",
      "partiallyNecessaryQty": "Не все количество продукта необходимо",
      "planned": "Запланировано",
      "delivered": "Доставлено",
      "orderPrice": "Цена заказа",
      "productDeletionMessage": "Удаление продукта в процессе",
      "productDeletionError": "Не удалось корректно удалить продукт",
      "productTitle": "Продукты",
      "released": "Выпущено",
      "not_released": "Не выпущено",
      "releaseDate": "Дата заказа",
      "save": "Сохранить",
      "supplies": {
        "status": "Статус",
        "actualDelay": "Фактическая задержка",
        "addedItems": "Добавленные элементы",
        "badFullTruckQuantity": "Заказ не соответствует ограничению на количество для полного грузовика",
        "badMOQOrder": "Заказ не соответствует ограничению MOQ",
        "badMOQSupply": "Поставка не соответствует ограничению MOQ",
        "badOrderFrequency": "Заказ не соответствует частотному ограничению",
        "cancel": "Отмена",
        "cancelEdit": "отменить редактирование",
        "deselectAll": "Снять все выделения",
        "clearSelection": "Очистить выбор",
        "componentShortageValidationTitle": "Подтвердить строку заказа с нехваткой компонентов?",
        "componentShortageValidationText": "Некоторые из компонентов, связанных с этой строкой заказа, отсутствуют на складе",
        "componentShortageCancelButton": "Отмена",
        "componentShortageConfirmButton": "Подтвердить строку заказа | Подтвердить строки заказа",
        "consistsSupplyBufferPolicyProducts": "Состоит из продуктов с политикой буфера поставок",
        "constraint": "Ограничение | Ограничение | Ограничения",
        "constraintFrequency": "Не соответствует требованиям частоты заказов",
        "constraintFullTruck": "Не соответствует требованиям полного грузовика",
        "constraintMOQ": "Не соответствует требованиям MOQ",
        "constraintsMetMessage": "Все ограничения выполнены",
        "constraintsNotMetMessage": "Некоторые продукты не соответствуют своим ограничениям",
        "customer": {
          "badLotSize": "Размер партии не соблюден",
          "display": "Клиент",
          "latestOrderDate": "Отправить до",
          "orderSent": "Отправлено",
          "orderValidated": "Подтверждено",
          "partner": "Поставщик",
          "validateBtn": "Подтвердить",
          "waitSendOrder": "Ожидает отправки",
          "waitValidateOrder": "Ожидает подтверждения"
        },
        "validate": "Подтвердить",
        "discard": "Отменить",
        "date": "Дата доставки",
        "dateRange": "Диапазон дат",
        "day": "день | день | дней",
        "days": "дней",
        "daysOfCoverage": "Покрытие запасов при доставке",
        "daysShort": "д",
        "delete": "Удалить",
        "deleteCurrentOrderSupplyWarningText": "Это удалит все запланированные поставки в этом заказе.",
        "deleteCurrentOrderWarningText": "Вы уверены, что хотите удалить этот заказ?",
        "deleteOrderError": "Не удалось удалить заказ",
        "deleteOrderText": "Вы уверены, что хотите удалить этот заказ? Это удалит все запланированные поставки в этом заказе | Вы уверены, что хотите удалить эти заказы? Это удалит все запланированные поставки в этих заказах",
        "deleteOrderLineText": "Вы уверены, что хотите удалить эту строку заказа? | Вы уверены, что хотите удалить эти строки заказа?",
        "deleteOrderTitle": "Удалить заказ | Удалить {count} заказов",
        "deleteOrderLineTitle": "Удалить строку заказа | Удалить {count} строк заказа",
        "deleteSelectedOrders": "Удалить выбранное",
        "deleteSuccess": "Заказ удален | {count} заказов удалено",
        "orderLineDeleteSuccess": "Строка заказа удалена | {count} строк заказа удалено",
        "deleteSupplyError": "Не удалось корректно удалить продукт",
        "deleteSupplyWarningText": "Вы уверены, что хотите удалить этот продукт?",
        "deleteWarningCancelButton": "Отмена",
        "deleteWarningConfirmButton": "Удалить заказ | Удалить заказы",
        "deleteOrderLineWarningConfirmButton": "Удалить строку заказа | Удалить строки заказа",
        "deleteWarningTitle": "Подтверждение удаления",
        "deletionMessage": "Удаление в процессе",
        "delivered": "Доставлено",
        "ifDelayed": "Если задерживается",
        "delivery": "Доставка",
        "deliveryDate": "Дата доставки",
        "deliveryDateInThePast": "Дата доставки из ERP в прошлом",
        "deliveryDateIsAdjusted": "Дата доставки Flowlity скорректирована на следующую возможную дату доставки",
        "deselectAllPage": "Снять все выделения на этой странице",
        "columns": "Столбцы",
        "resetOrder": "Сбросить порядок",
        "shownInTable": "Показано в таблице",
        "hiddenInTable": "Скрыто в таблице",
        "resetModalCancelButton": "Отмена",
        "resetModalConfirmButton": "Вернуться к настройкам по умолчанию",
        "resetModalText": "Вы потеряете текущие активные столбцы и порядок",
        "resetModalTitle": "Вернуться к настройкам столбцов по умолчанию?",
        "productSite": {
          "subContracted": "С компонентами"
        },
        "details": {
          "mixedUnits": "смешанные единицы",
          "actualDelay": "Фактическая задержка",
          "actualDelayTooltipHeader": "Задержка по сравнению с первоначальной датой доставки",
          "badMoq": "MOQ поставщика не соблюден",
          "badFullTruckQuantity": "Заказ не соответствует ограничению на количество для полного грузовика",
          "cancelEdit": "Отменить изменения",
          "constraintFrequency": "Не соответствует требованиям частоты заказов",
          "combiningOrders": "Заказ обновляется и объединяется...",
          "combiningOrdersDesc": "Не волнуйтесь, если этот процесс займет некоторое время. Нам просто нужно тщательно обновить каждый продукт",
          "combiningOrdersSuccess": "Заказ обновлен и объединен",
          "updatingDeliveryDateTitle": "Заказ обновляется...",
          "updatingDeliveryDateDescription": "Не волнуйтесь, если этот процесс займет некоторое время. Нам просто нужно тщательно обновить каждый продукт",
          "updatingDeliveryDateSuccess": "Заказ обновлен",
          "dates": "Даты",
          "day": "день | день | дней",
          "days": "дней",
          "delayBelowAvg": "Фактическая задержка ниже покрытия задержки",
          "delayNotCovered": "Фактическая задержка не покрыта применяемым буфером",
          "shortForDay": "д",
          "deleteWarningTitle": "Удалить заказ {orderId}",
          "deleteCurrentOrderWarningText": "Вы уверены, что хотите удалить этот заказ? Это удалит все запланированные поставки в этом заказе.",
          "deleteOrderSuccess": "Заказ удален",
          "deleteOrderError": "Не удалось удалить текущий заказ",
          "deleteWarningConfirmButton": "Удалить заказ",
          "deleteWarningCancelButton": "Отмена",
          "deleteSuccess": "Заказ был удален",
          "deleting": "Заказ удаляется...",
          "deliveryDate": "Дата доставки",
          "editingModalTitle": "Редактирование",
          "purchaseRequisition": "Запрос на покупку для",
          "purchaseOrder": "Заказ на покупку для",
          "estDeliveryDate": "Ожидаемая дата доставки",
          "fullTruckQty": "Количество для полного грузовика",
          "groupedBy": "Сгруппировано по тегу",
          "initialDeliveryDate": "Первоначальная дата доставки",
          "latestOrderDate": "Крайний срок размещения заказа",
          "alerts": "Оповещения",
          "mergeWarningCancelButton": "Отмена",
          "mergeWarningConfirmButton": "Сохранить и объединить",
          "mergeWarningText": "Уже существует заказ с той же датой и поставщиком. Объединение заказов переместит все строки текущего заказа в другой.",
          "mergeWarningTitle": "Хотите объединить два заказа?",
          "moq": "MOQ поставщика",
          "noResultsFound": "Заказ не найден",
          "noResultsFoundSubTitle": "Страница с этим идентификатором заказа не существует или была удалена",
          "backOrdersList": "Вернуться к списку заказов",
          "orderConfirm": "Подтвердить заказ",
          "discardOrder": "Отменить заказ",
          "orderConfirmed": "Подтверждено",
          "orderConfirmedSuccess": "Заказ подтвержден",
          "orderFrequency": "Частота заказов",
          "orderExternalId": "Внешний идентификатор заказа",
          "orderIsUpdating": "Заказ обновляется...",
          "orderQuantityTitle": "Количество заказа",
          "orderUnconfirmSuccess": "Подтверждение отменено",
          "partiallyNecessaryQty": "Не все количество продукта необходимо",
          "planned": "Запланировано",
          "delivered": "Доставлено",
          "orderPrice": "Цена заказа",
          "productDeletionMessage": "Удаление продукта в процессе",
          "productDeletionError": "Не удалось корректно удалить продукт",
          "productTitle": "Продукты",
          "released": "Выпущено",
          "not_released": "Не выпущено",
          "releaseDate": "Дата выпуска",
          "save": "Сохранить",
          "supplies": {
            "status": "Статус",
            "actualDelay": "Фактическая задержка",
            "averageDelay": "Покрытие задержки",
            "coverage": "Покрытие запасов",
            "atDelivery": "при доставке",
            "current": "текущий",
            "value": "Значение",
            "deleteSupplyAndOrderSuccess": "Продукт удален и заказ удален | Продукт удален и заказ удален | Продукт удален",
            "deleteSupplyAndOrderWarningTitle": "Удалить продукт и удалить заказ? | Удалить продукт и удалить заказ? | Удалить продукт {supplyId} из заказа?",
            "deleteSupplyAndOrderWarningText": "Это единственный продукт в текущем заказе, поэтому планируемое пополнение и сам заказ будут удалены | Это единственный продукт в текущем заказе, поэтому планируемое пополнение и сам заказ будут удалены | Это также удалит планируемое пополнение",
            "deleteSupplyAndOrderWarningConfirmButton": "Удалить продукт и удалить заказ | Удалить продукт и удалить заказ | Удалить из заказа",
            "deleteWarningCancelButton": "Отмена",
            "estDeliveryDate": "Ожидаемая дата доставки",
            "inputError": "Не может быть пустым",
            "lotSize": "Размер партии",
            "moq": "MOQ",
            "name": "Название продукта",
            "orderUpdatedSuccess": "Заказ обновлен",
            "quantity": "Количество",
            "netRequirement": "Чистое требование",
            "netRequirementSubTitle": "c продуктовыми ограничениями",
            "netRequirementTooltip": "Минимальное требуемое количество c учетом продуктовых ограничений. Ограничения поставщика исключены",
            "netRequirementNoConstraintsSubTitle": "без продуктовых ограничений",
            "netRequirementQtySubTitle": "c продуктовыми ограничениями, шт",
            "refId": "Ссылка",
            "stock": "Запасы",
            "tags": "Теги"
          },
          "supplierConstraints": "Ограничения поставщика",
          "supplyIsUpdating": "Поставка обновляется... | Поставка обновляется... | Поставки обновляются...",
          "orderFlowlityId": "Идентификатор заказа Flowlity",
          "orderLineId": "Идентификатор строки заказа",
          "totalQuantity": "Общее количество",
          "uncertainty": {
            "bltPolicy": "Применена политика временного буфера",
            "bltPolicyDesc": "Даты доставки назначаются заранее в соответствии с требованиями планирования.",
            "bsPolicy": "Применена политика буфера запасов",
            "bsPolicyDesc": "Минимальный запас скорректирован с учетом этих задержек",
            "policyTitle": "На этот продукт часто бывают задержки."
          },
          "unnecessaryQty": "Все количество продукта в заказе не нужно",
          "updateSuppliesQuantitiesSuccess": "Заказ обновлен",
          "confirmed": "Подтверждено"
        },
        "discardChanges": "Отменить изменения",
        "discardOrder": "Отменить заказ",
        "download_selected": "Скачать",
        "download": "Скачать",
        "downloadOrder": "Скачать заказ",
        "edit": "редактировать",
        "estDeliveryDate": "Ожидаемая дата доставки",
        "netRequirement": "Чистое требование",
        "netRequirementSubTitle": "c продуктовыми ограничениями",
        "netRequirementQtySubTitle": "c продуктовыми ограничениями, шт",
        "netRequirementValueSubTitle": "c продуктовыми ограничениями, €",
        "netRequirementTooltip": "Минимальное требуемое количество c учетом продуктовых ограничений. Ограничения поставщика исключены",
        "expectedDelivery": "Ожидаемая доставка",
        "fullTruckQty": "Количество для полного грузовика",
        "goodMoq": "Минимальное количество заказа",
        "groupedBy": "Сгруппировано по",
        "in1month": "Через 1 месяц",
        "in2months": "Через 2 месяца",
        "in2Weeks": "Через 2 недели",
        "in3months": "Через 3 месяца",
        "in6months": "Через 6 месяцев",
        "in1year": "Через 1 год",
        "includesItems": "Количество продуктов",
        "initialDeliveryDate": "Первоначальная дата доставки",
        "inTotal": "Всего",
        "issueMOQ": "у продукта проблема с MOQ или размером партии | у продуктов проблема с MOQ или размером партии",
        "items": "продукт | продукты",
        "latest_order_date": "Отправить до",
        "latestOrderDate": "Крайний срок размещения заказа",
        "alerts": "Оповещения",
        "leadTime": "Время выполнения заказа",
        "lotSize": "Размер партии",
        "mergeWarningCancelButton": "Отмена",
        "mergeWarningConfirmButton": "Да, подтвердить",
        "mergeWarningText": "Уже существует заказ на эту дату и поставщика. Вы хотите объединить два заказа?\nЭто добавит текущие продукты и количества в заказ, найденный на эту новую дату.",
        "mergeWarningTitle": "Подтверждение объединения",
        "moq": "Минимальное количество заказа",
        "noOrders": "Заказы не найдены",
        "noOrdersTags": "Теги не найдены",
        "nOrdersFound": "0 заказов найдено | 1 заказ найден | {count} заказов найдено",
        "noComponentsFound": "Компоненты не найдены",
        "nSubContractedComponentsFound": "0 компонент найдено | 1 компонент найден | {count} компонентов найдено",
        "orderAndSuppliesIssues": "Проблема с продуктом и ограничениями поставщика",
        "orderAndSupplyConstraintNotMet": "Некоторые ограничения на уровне заказа и продукта не выполнены",
        "orderConfirm": "Подтвердить заказы",
        "orderLineConfirm": "Подтвердить строки заказа",
        "orderConfirmed": "Подтверждено",
        "orderConfirmError": "Произошла ошибка при подтверждении заказов",
        "orderConfirmSuccess": "Заказ подтвержден | {count} заказов подтверждено",
        "orderLineConfirmSuccess": "Строка заказа подтверждена | {count} строк заказа подтверждено",
        "orderSendError": "Произошла ошибка при отправке заказов",
        "orderSendSuccess": "Заказ отправлен | {count} заказов отправлено",
        "orderConstraintNotMet": "Некоторые ограничения на уровне заказа не выполнены",
        "orderConstraints": "Ограничения заказа",
        "orderDetailMessageTitle": "Сообщения",
        "orderDiscard": "Отменить",
        "orderDiscardSuccess": "Подтверждение заказа отменено | {count} подтверждений заказов отменено",
        "orderFrequency": "Частота заказов",
        "orderExternalIdSubtitle": "внешний",
        "orderIsConfirming": "Заказ подтверждается... | Заказы подтверждаются...",
        "orderLineIsConfirming": "Строка заказа подтверждается... | Строки заказа подтверждаются...",
        "orderIsDiscarding": "Заказ отменяется... | Заказы отменяются...",
        "orderLineIsDiscarding": "Строка заказа отменяется... | Строки заказа отменяются...",
        "orderIsSending": "Заказ отправляется... | Заказы отправляются...",
        "orderIsLoading": "Заказ загружается...",
        "orderIssue": "Проблема с заказом",
        "orderIssues": "Проблема с ограничениями поставщика",
        "orderIsUpdating": "Заказ обновляется...",
        "orderModalAddProductsButton": "Добавить продукты",
        "orderModalTitle": "для",
        "orders": "Заказы | Заказ | Заказы",
        "Orders": "Заказы | Заказ | Заказы",
        "OrderLines": "Строки заказа | Строка заказа | Строки заказа",
        "orderTitle": "Заказы",
        "orderTitleDetail": "Детали",
        "planned": "Запланировано",
        "planning": {
          "backToOrder": "НАЗАД К ЗАКАЗАМ",
          "emptyTitle": "",
          "emptySubtitle1": "",
          "emptySubtitle2": "",
          "expectedDelivery": "Ожидаемая доставка",
          "lotSize": "Размер партии",
          "moq": "MOQ",
          "orderPlanningTitle": "Планирование запасов продуктов",
          "stackChoice": "стек",
          "orderDeleted": "Пополнение удалено и заказ удален",
          "open": "Открыть планирование"
        },
        "price": "Цена",
        "orderPrice": "Цена заказа",
        "product": "Продукт",
        "productIssue": "Проблема с продуктом",
        "productIssues": "Проблемы с продуктом",
        "products": "Продукты",
        "productsCount": "Строки заказа",
        "productsHaveIssues": "0 | 1 продукт имеет проблему с MOQ/размером партии | {count} продуктов имеют проблему с MOQ/размером партии",
        "released": "Выпущено",
        "not_released": "Не выпущено",
        "quantity": "Количество",
        "reference": "Ссылка",
        "releaseDate": "Дата выпуска",
        "reset": "Сброс",
        "salesOrders": "Заказы на продажу",
        "save": "Сохранить",
        "search": "Поиск по идентификатору заказа, поставщику или продукту",
        "secondaryUnit": "Вторичная единица",
        "select_all": "Выбрать все",
        "selectAllPage": "Выбрать все на этой странице",
        "selected_orders": "Выбранные заказы | Выбранные заказы",
        "selected": "Выбрано",
        "selectedTags": "1 тег выбран | {count} тегов выбрано",
        "sftp_exported": "Экспортировано в SFTP",
        "sftp_selected": "Экспорт в SFTP",
        "sftpError": "Ошибка экспорта заказов, пожалуйста, свяжитесь с flowlity",
        "sftpSuccess": "1 заказ экспортирован в SFTP | {count} заказов экспортировано в SFTP",
        "sftpReExportTitle": "Повторно экспортировать заказы в SFTP?",
        "sftpReExportDescription": "Выбранные заказы уже экспортированы в SFTP, повторный экспорт создаст дубликаты",
        "sftpReExportBtn": "Повторно экспортировать в SFTP",
        "sftpWhatsExportTitle": "Какие заказы экспортировать?",
        "sftpWhatsExportDescription": "Некоторые заказы уже экспортированы в SFTP, повторный экспорт создаст дубликаты",
        "sftpWhatsExportAllBtn": "Экспортировать все",
        "sftpWhatsExportNewBtn": "Экспортировать новые заказы",
        "ship_before": "Отправить до",
        "subContracted": {
          "nextDeliveryDate": "Следующая дата доставки",
          "nextDeliveryQty": "Следующее количество доставки",
          "productExternalId": "Ссылка",
          "productName": "Название продукта",
          "quantity": "Количество",
          "stock": "Количество на складе",
          "current": "текущий",
          "required": "требуется",
          "unitIssues": "Проблема с единицами",
          "unitsIssueTooltip": "Существует несоответствие единиц между BOM и приложением Flowlity"
        },
        "supplier": {
          "badLotSize": "Размер партии не соблюден",
          "display": "Поставщик",
          "latestOrderDate": "Отправить до",
          "leadTimeNotSet": "Время выполнения заказа не определено. Установите его.",
          "orderSent": "Отправлено",
          "orderValidated": "Принято",
          "partner": "Клиент",
          "validateBtn": "Принять",
          "waitSendOrder": "Ожидает отправки",
          "waitValidateOrder": "Ожидает принятия"
        },
        "suppliers": "Поставщики | Поставщик | Поставщики",
        "supplyConstraintNotMet": "Некоторые продукты не соответствуют своим ограничениям",
        "tags": "Тег | Теги",
        "orderFlowlityIdSubtitle": "заказ Flowlity",
        "id": "ИД",
        "orderLineSubtitle": "строка заказа",
        "firmOrders": "Твердые заказы",
        "firmOrdersSubtitle": "Кол-во",
        "thisWeek": "На этой неделе",
        "totalQuantity": "Общее количество",
        "unnecessarySupply": "необходимо | необходимо | необходимо",
        "updateError": "Не удалось корректно обновить заказ(ы)",
        "updateSuccess": "Заказ(ы) успешно обновлен(ы)",
        "confirmed": "Подтверждено",
        "value": "Значение",
        "volume": "Количество",
        "displayedOnStatuses": "Отображается в статусах:",
        "orderLine": "Строки заказа",
        "orderLines": {
          "confirmed": "Подтверждено",
          "create": "Создать",
          "createdMessage": "Строка заказа создана",
          "createOrderLine": "Создать строку заказа",
          "delivered": "Доставлено",
          "deliveryDate": "Дата доставки",
          "discardChangesBtn": "Отменить изменения",
          "discardChangesDescription": "Вы потеряете все изменения, которые были внесены",
          "discardChangesTitle": "Отменить изменения?",
          "enterQty": "Введите количество",
          "errorMessage": "Произошла ошибка при создании строки заказа",
          "fixedFromPlanning": "Закреплено из планирования",
          "not_released": "Не выпущено",
          "planned": "Запланировано",
          "product": "Продукт",
          "productExternalId": "Ссылка",
          "productName": "Название продукта",
          "productDescription": "Описание продукта",
          "quantity": "Количество",
          "released": "Выпущено",
          "selectDeliveryDate": "Выберите дату",
          "selectProduct": "Выберите продукт",
          "selectSupplier": "Выберите поставщика",
          "subComponentsNoResultsFound": "Данные по компонентам не найдены",
          "subComponentsNoResultSubTitle": "Не удалось получить действительные данные по компонентам из данных BOM",
          "supplier": "Поставщик",
          "componentShortage": "Нехватка компонентов",
          "productConstraint": "Ограничение продукта",
          "supplierConstraint": "Ограничение поставщика",
          "allocationNeeded": "Требуется распределение"
        },
        "alertsDropdown": {
          "component_shortage": "Нехватка компонентов",
          "product_constraint": "Проблема с ограничениями продукта",
          "supplier_constraint": "Проблема с ограничениями поставщика",
          "allocation_needed": "Требуется распределение"
        },
        "computing": "Вычисление"
      }
    }
  }
}
