{
  "selectionIsDisabledWhenSimulationIsValidated": "La sélection est désactivée lorsque la simulation est validée",
  "selectionIsOnlyAvailableForMtsFlowlityAiProducts": "La sélection est uniquement disponible pour les produits MTS Flowlity AI",
  "selected": "{count} sélectionné | {count} sélectionné | {count} sélectionné",
  "itemsSelected": "Éléments sélectionnés",
  "errorWhileFetchingTagCategories": "Une erreur est survenue lors de la récuperation des catégories de tags",
  "tagCategoryUpdateSuccess": "La catégorie de tags a été mise à jour avec succès",
  "tagCategoryUpdateError": "Une erreur est survenue lors de la mise à jour de la catégorie de tags",
  "tagCategoryDeleteSuccess": "La catégorie de tags \"{name}\" a été supprimée avec succès",
  "tagCategoryDeleteError": "Une erreur est survenue lors de la suppression de la catégorie de tags",
  "deleteTagCategory": "Supprimer la catégorie de tags ?",
  "deleteTagCategoryButton": "Supprimer la catégorie",
  "tagCategoryCreateSuccess": "La catégorie de tags a été créée avec succès",
  "tagCategoryCreateError": "Une erreur est survenue lors de la création de la catégorie de tags",
  "categoryName": "Nom de la catégorie",
  "optional": "Optionnel",
  "NProductsSelected": "0 produit sélectionné | 1 produit sélectionné | {countDisplay} produits sélectionnés",
  "downloadProductsCSVError": "Une erreur s'est produite lors du téléchargement du CSV des produits.",
  "cannotOpenDetailsWhenSelectingAll": "Vous ne pouvez pas ouvrir les détails lorsque tous les éléments sont sélectionnés",
  "downloadCSV": "Télécharger la liste en CSV",
  "dueDate": "Date de fin de période figée",
  "fixPeriod": "Figer la période de recommandations",
  "fixPeriodTitle": "Figer la période pour les produits sélectionnés ?",
  "discardPeriod": "Défiger la période de recommandations",
  "discardPeriodTitle": "Défiger la période de recommandations pour les produits sélectionnés ?",
  "discardPeriodDescription": "Si vous défigez, Flowlity recommandera un plan d'approvisionnement pour toute la période de planification.",
  "discardPeriodConfirmButton": "Défiger",
  "discardPeriodSuccess": "La fixation de période a été annulée",
  "discardPeriodError": "Une erreur est survenue lors de l'annulation de la fixation de période",
  "fixPeriodSuccess": "La période a été figée",
  "fixPeriodError": "Une erreur est survenue lors de la fixation de la période",
  "downloadCsv": "Télécharger la liste en CSV",
  "noBom": "Ce produit ne fait partie d'aucun BOM",
  "deleteOrder": "Supprimer la commande",
  "open": "Ouvrir",
  "close": "Fermer",
  "orderDetailsAction": "{action} les détails de la commande",
  "editInventoryStrategy": "Modifier la stratégie de stock",
  "selectCurrentPage": "Sélectionner la page",
  "selectAllPages": "Tout sélectionner",
  "clearSelection": "Désélectionner",
  "units": "unité | unité | unités",
  "mixed": "mixte",
  "myStockMaxValue": "Ma valeur de stock maximale",
  "searchASupplier": "Rechercher un fournisseur",
  "supplier": "Fournisseur",
  "delayCoverage": "Couverture du délai",
  "categoriesOfTags": "Catégories de tags",
  "here": "ici",
  "useDefaultBufferPolicy": "Utiliser la politique par défaut",
  "supplierDelay": "Couverture du délai",
  "supplierMode": "Valeur",
  "ropLevel": "Niveau de point de commande",
  "quantity": "Quantité",
  "qty": "Qté",
  "coverage": "Couverture",
  "type": "Type",
  "time": "Temps",
  "stock": "Stock",
  "maxValue": "Ma valeur maximale",
  "minValue": "Ma valeur minimale",
  "bufferLevel": "Niv. de stock sécurité",
  "bufferLevelTip": "Le niveau de stock sécurité Flowlity aura un impact sur votre niveau de stock sécurité. Plus le niveau de stock sécurité est faible, plus votre niveau minimum sera bas. Plus le niveau de stock sécurité est élevé, plus votre niveau minimum sera élevé.",
  "back": "Retour",
  "apply": "Appliquer",
  "select": "Sélectionner",
  "strategyType": "Type de stratégie",
  "strategyTypeDesc": "Appliquez la stratégie MTO ou MTS pour tous les produits et spécifiez les configurations détaillées.",
  "supplyBufferPolicy": "Politique de buffer",
  "supplyBufferPolicyDesc": "Spécifiez si la politique de buffer sera Temps, Stock, Aucun, ou utilisez simplement les configurations par défaut.",
  "myStockMax": "Mon stock max",
  "myStockMaxDesc": "Définir la valeur maximale de stock pour tous les produits.",
  "whatDoYouWantToEdit": "Que voulez-vous éditer ?",
  "viewCreationSuccess": "La vue a bien été créée",
  "viewUpdateSuccess": "La vue a bien été mise à jour",
  "viewRemovalSuccess": "La vue a bien été supprimée",
  "excludeNewProducts": "Exclure les nouveaux produits",
  "excludeNewProductsTooltip": "Exclure les produits qui n'étaient pas disponibles pendant la période comparée précédente",
  "sum": "Somme",
  "avg": "Moyenne",
  "samePeriodLastYear": "La même période l'année dernière",
  "previousPeriod": "La période précédente",
  "startUsingFlowlity": "Le début de l'utilisation de Flowlity",
  "compareWith": "À comparer avec",
  "period": "Période",
  "lastMonth": "Mois dernier",
  "lastQuarter": "Trimestre dernier",
  "last6Months": "6 derniers mois",
  "lastYear": "Année dernière",
  "zeroStockDays": "Jours de stock à zéro",
  "stockCoverage": "Couverture de stock",
  "pastDemand": "Demande passée",
  "inventoryLevel": "Niveau de stock",
  "average": "Moyenne",
  "homeKpiPercentageTooltip": "Différence en pourcentage entre les périodes:",
  "addView": {
    "title": "Ajouter une nouvelle vue",
    "desc": "Une vue vous permet de personnaliser la liste de produits en sauvegardant filtres, colonnes et tri."
  },
  "clearAllFilters": "Désélectionner les filtres",
  "reset": "Réinitialiser",
  "saveView": "Enregistrer la vue",
  "enterName": "Entrez un nom",
  "deleteViewTitle": "Voulez-vous supprimer la vue \"{name}\" ?",
  "deleteViewDescription": "Si vous supprimez cette vue, vous perdrez toutes les configurations telles que les filtres et les paramètres de colonne.",
  "reorderViews": "Réorganiser les vues",
  "renameView": "Renommer la vue",
  "name": "Nom",
  "duplicate": "Dupliquer",
  "rename": "Renommer",
  "reorder": "Réorganiser",
  "addANewView": "Ajouter une nouvelle vue",
  "defaultView": "Vue par défaut",
  "discardModalTitle": "Annuler les modifications ?",
  "openDemand": "Ouvrir la demande",
  "openPlanning": "Ouvrir le planning",
  "product": "Produit | Produit | Produits",
  "by": "Par",
  "day": "Jour | Jour | Jours",
  "week": "Semaine | Semaine | Semaines",
  "month": "Mois",
  "quarter": "Trimestre",
  "year": "An",
  "discardChanges": "Annuler les modifications",
  "save": "Enregistrer",
  "customers": "Clients",
  "andXMore": "et {count} de plus...",
  "noSuppliersFound": "Aucun fournisseur trouvé",
  "noAlertsAvailable": "Aucune alerte disponible",
  "learnMore": "En savoir plus",
  "new": "Nouveau",
  "days": "jour | jour | jours",
  "ascending": "Ascendant",
  "descending": "Descendant",
  "baseTable": {
    "loadOlderData": "Données passées",
    "simulation": "simulation uniquement",
    "simulationTooltipTitle": "Changement sur l'approvisionnement ferme",
    "simulationTooltipSubtitle": "Les modifications ne seront pas sauvegardés.",
    "simulationTooltipText": "L'approvisionnement ferme ne peut être modifié que dans votre ERP."
  },
  "cancel": "Annuler",
  "confirm": "Confirmer",
  "undo": "Annuler",
  "dateRange": "Période",
  "dataJobsAlerts": {
    "error": {
      "subscriptionError": "Une erreur est survenue lors de la récuperation du status de la mise à jour des données"
    }
  },
  "backToEditing": "Retour à l'édition",
  "delete": "Supprimer",
  "deleteOrdersWarningText": "Êtes-vous sûr de vouloir supprimer les commandes sélectionnées ?",
  "deleteWarningCancelButton": "Annuler",
  "deleteWarningConfirmButton": "Je confirme",
  "deleteWarningTitle": "Confirmation de suppression",
  "deliveryDate": "Date de livraison",
  "demand": {
    "chart": {
      "allOrders": "Expéditions futures",
      "demandFinalForecast": "Prédiction finale",
      "demandForecastFlowlity": "Prédiction Flowlity",
      "demandForecastExternal": "Prédiction externe",
      "firmOrders": "Expéditions confirmées",
      "plannedOrders": "Expéditions planifiées",
      "historicalDemand": "Demande passée",
      "confidenceInterval": "Intervalle de confiance",
      "maxFlowPred": "Intervalle de confiance - max",
      "minFlowPred": "Intervalle de confiance - min",
      "myforecast": "Ma prédiction",
      "supplierDemand": "Consommation réelle du client",
      "title": "Prédictions"
    }
  },
  "demandTable": {
    "common": {
      "aggregatedView": "Vue agrégée",
      "clientDemand": "Client",
      "finalForecast": "Prédiction finale",
      "demandForecastExternal": "Prédiction externe",
      "finalForecastExpl": "La prédiction totale prenant en compte la demande future, Ma prévision et la prédiction de base (Flowlity ou externe)",
      "externalForecast": "Prédiction externe",
      "externalForecastExpl": "Prédiction ou demande dépendante fournie par une source externe",
      "futureDemand": "Demande future",
      "futureDemandExpl": "Demande future enregistrée comme ferme ou planifiée",
      "firmDemand": "Demande ferme",
      "firmDemandExpl": "Demande ferme, prévue pour le futur",
      "plannedDemand": "Demande planifiée",
      "plannedDemandExpl": "Demande planifiée pour le futur",
      "updateError": "Une erreur s'est produite pendant la sauvegarde de votre donnée",
      "updateSuccess": "La donnée a bien été enregistrée"
    },
    "discardChanges": "Annuler",
    "editingMyForecast": "Édition",
    "regularView": {
      "pastDemand": "Demande passée",
      "pastDemandExpl": "Demande enregistrée dans le passé",
      "myForecast": "Ma prédiction",
      "myForecastExpl": "Prédiction manuelle influençant la prédiction finale par rapport à la prédiction de base (Flowlity ou Externe)",
      "flowlityForecast": "Prédiction Flowlity",
      "flowlityForecastExpl": "Prédiction générée par l'IA de Flowlity"
    },
    "saveUpdateButton": "Sauvegarder",
    "saving": "Sauvegarde en cours",
    "supplierView": {
      "allOrders": "Expéditions futures",
      "firmOrders": "Expéditions confirmées",
      "plannedOrders": "Expéditions planifiées",
      "historicalDemand": "Consommation réelle du client"
    }
  },
  "deselectAll": "Tout désélectionner",
  "discardCancelButton": "Continuer d'éditer",
  "discardConfirmButton": "Annuler les modifications",
  "discardText": "Vous perdrez tous les modifications qui ont été faits pendant l'édition.",
  "discardTitle": "Annuler les modifications ?",
  "productsCount": "0 produits | 1 produit | {count} produits",
  "tagsCount": "0 tags | 1 tag | {count} tags",
  "dropdown": {
    "alerts": {
      "title": "Alertes"
    },
    "attributes": "Attributs | Attribut: {name} | {count} Attributs",
    "customers": "Clients",
    "dateRange": "Période",
    "deselect": "Désélectionner",
    "deselectAll": "Tout désélectionner",
    "products": "Produits",
    "search": "Recherche {suffix}",
    "selectAll": "Tout sélectionner",
    "subContractedComponents": "Composants",
    "suppliers": {
      "title": "Fournisseurs | Fournisseur: {name} | {count} Fournisseurs"
    },
    "tags": {
      "title": "Tags | Tag: {name} | {count} Tags",
      "counter": "Aucun tag disponible | 1 tag disponible | {count} tag disponibles",
      "cell": {
        "displayTitle": "- | {name} | {count} tags"
      }
    }
  },
  "emptyData": "Pas de donnée disponible",
  "header": {
    "editProfile": "Modifier votre profil",
    "lang": "Choix de langue",
    "logout": "Déconnexion",
    "whatsNew": "Nouveautés",
    "settings": "Configurations"
  },
  "credits": {
    "available": "0 crédit optim disponible | 1 crédit optim disponible | {balance} crédits optim disponibles",
    "getMore": "Obtenir plus de crédits",
    "currentLimit": "Actuellement, vous avez {limit} crédits optim disponibles chaque mois",
    "needTitle": "Combien de crédits par mois avez-vous besoin ?",
    "credits/month": "crédits/mois",
    "requestWillBeSentExplanation": "Votre demande sera envoyée à support{'@'}flowlity.com",
    "cancel": "Annuler",
    "topupSubject": "{companyName} : augmenter les crédits pour {siteName}",
    "topupBody": "Veuillez augmenter la limite mensuelle disponible à {amount} crédits/mois pour {siteName} / {companyName} (ID du site : {siteId})",
    "notEnough": "Pas assez de crédits pour exécuter la planification",
    "contactFlowlity": "Obtenez plus de crédits en contactant Flowlity"
  },
  "InputLineCell": {
    "dayMarkedAs": "Ce jour est",
    "empty": "vide",
    "flowlitySupply": "Cette valeur est recommandée par Flowlity",
    "supplyIsConfirmed": "Cet approvisionnement a été validé",
    "areValidated": "est validé | est validé | sont validés",
    "fullyFrozen": "gelé | gelée",
    "lotSize": "Taille de lot",
    "monthMarkedAs": "Ce mois est",
    "moq": "Mini de commande",
    "originalValue": "Valeur initiale",
    "partlyFrozen": "partiellement gelé | partiellement gelée",
    "validated": "Attention, la commande pour cette supply a été validée",
    "weekMarkedAs": "Cette semaine est"
  },
  "lastOrderDate": "Date d'échéance",
  "lastConfirmedDeliveryDate": "Date de livraison confirmée",
  "lastConfirmedDeliveryDateTitle": "Date de livraison",
  "lastConfirmedDeliveryDateSubtitle": "dernière confirmation",
  "modal": {
    "backToList": "Retour à liste",
    "backToOrder": "Retour à la commande",
    "backToCapacityUnit": "Retour à la capacité"
  },
  "no": "Non",
  "notification": {
    "error": "Erreur",
    "success": "Succès"
  },
  "dashboard": {
    "actual": "Actuel",
    "daysOfCoverage": "Couverture de stock",
    "days": "jours",
    "tagDropdownPlaceholder": "Filtrer les résultats par tag"
  },
  "planning": {
    "chart": {
      "allOrders": "Commandes",
      "currentDay": "J-1",
      "week": "[S]",
      "weekTooltip": "[Semaine]",
      "Expired": "Expirant",
      "firmOrders": "Commandes fermes",
      "intervalMax": "Flowlity Max",
      "intervalMin": "Flowlity Min",
      "Maximum": "Maximum",
      "Minimum": "Minimum",
      "myMinimum": "Mon Stock Min",
      "reorderPoint": "Point de Commande (ROP)",
      "plannedOrders": "Commandes planifiées",
      "Stock": "Stock",
      "simulatedMin": "Nouveau Stock Min",
      "myStockMax": "Mon Stock Max"
    },
    "error": {
      "expiredStock": "Une erreur est survenue lors de la récuperation du stock expiré",
      "storageSites": "Une erreur est survenue lors de la récuperation du stock expiré"
    }
  },
  "planningTable": {
    "aggregatedView": "Vue agrégée",
    "currentDay": "J-1",
    "restOf": "Fin",
    "discardCancelButton": "Continuer d'éditer",
    "discardChanges": "Annuler les modifications",
    "discardConfirmButton": "Annuler les modifications",
    "discardText": "Vous ne pourrez plus récupérer les modifications effectuées.",
    "discardTitle": "Annuler les modifications ?",
    "editingMySupply": "Édition",
    "finalForecast": "Prédiction finale",
    "justInTime": "Ce produit est paramétré pour ne pas avoir de plan d'approvisionnement proposé",
    "leadTime": "jours d'appro",
    "maximum": "Flowlity Max",
    "minimum": "Flowlity Min",
    "myMinimum": "Mon Stock Min",
    "reorderPoint": "Point de Commande (ROP)",
    "severalWarningsFound": "{count} alertes ont été constatés avec le produit",
    "noStock": "Nous n’avons pas reçu de donnée de stock pour ce produit. Nous avons donc défini le stock à 0",
    "noSupplierWarning": "Ce produit n'a aucun fournisseur lié, veuillez ajouter des fournisseurs",
    "orders": "Mon Plan",
    "productAdminPage": "administration du produit",
    "recomputeSuppliesInfo": "Nous manquons de données historiques sur ce produit pour recalculer des recommandations de supply",
    "recomputeSuppliesSuccess": "Les recommandations de supply ont été recalculées, rafraichissez la page pour les voir",
    "saveConfirmButton": "Oui",
    "saveTitle": "Appliquer les modifications et mettre à jour les commandes ?",
    "saveUpdateButton": "Sauvegarder",
    "saving": "Sauvegarde en cours",
    "status3Supplies": "Commandes fermes",
    "plannedOrders": "Commandes planifiées",
    "stock": "Stock",
    "averageFinalForecast": "Couverture de stock en jours",
    "stockExpired": "Stock expirant"
  },
  "products": "Produits",
  "productSearch": {
    "aboveFlowlityMax": "Au-dessus du stock optimal",
    "alerts": "Alertes",
    "events": "Événements",
    "belowFlowlityMin": "En dessous du stock optimal",
    "expiringStocks": "Stock expiré",
    "outOfStock": "Rupture de stock",
    "selectedAlerts": "0 Alerte | 1 Alerte | {count} Alertes",
    "selectedEvents": "0 Événement | 1 Événement | {count} Événements",
    "selectedProductsFiltersTags": "0 Tag | 1 Tag | {count} Tags",
    "lastNDays": "les {count} derniers jours",
    "lastNDaysCapitalized": "@.capitalize:productSearch.lastNDays",
    "nextNDays": "{count} prochains jours",
    "nextNDaysCapitalized": "@.capitalize:productSearch.nextNDays",
    "wholeHorizon": "Tout l'horizon",
    "daysToAct": "Jours pour agir",
    "daysToActExplanation": "Nombre de jours restants pour résoudre le problème. Cette option prend en compte le paramètre \"délai de livraison\", représentant ainsi l'urgence de la résolution de l'alerte. Si plusieurs fournisseurs existent, le délai moyen est pris en compte",
    "toActWithin": "Dans les @:productSearch.nextNDays",
    "alertDate": "Date de l'alerte",
    "alertDateExplanation": "Nombre de jours avant que le problème ne se soit produit",
    "alertDuration": "Durée de l'alerte",
    "alertValue": "Valeur de l'alerte",
    "stockAlerts": "Alertes de stock",
    "demandAlerts": "Alertes de demande",
    "externalForecastAlerts": "Alertes de prédiction externe"
  },
  "search": "Rechercher {suffix}",
  "searchBySite": "Rechercher par site",
  "selectedSubContractedComponents": "0 Composant | 1 Composant | {count} Composants",
  "selectedSuppliers": "0 Fournisseur | 1 Fournisseur | {count} Fournisseurs",
  "selectedCustomers": "0 Client | 1 Client | {count} Clients",
  "selectedAttributes": "O Attribut | 1 Attribut | {count} Attributs",
  "site": "Site",
  "siteDropdown": {
    "discardCancelButton": "Continuer d'éditer",
    "discardConfirmButton": "Annuler les modifications",
    "discardText": "Vous ne pourrez plus récupérer les modifications effectuées.",
    "discardTitle": "Annuler les modifications ?"
  },
  "suppliers": "Fournisseurs",
  "table": {
    "cell": {
      "tags": {
        "displayText": "- | {name} | {count} tags"
      },
      "products": {
        "displayText": "- | {count} | {count}"
      },
      "andMore": "et {count} plus..."
    }
  },
  "updateError": "Un problème a été rencontré pendant la mise à jour des commandes",
  "updateSuccess": "Commande(s) correctement mise(s) à jour",
  "yes": "Oui",
  "yesterday": "Hier",
  "today": "Aujourd'hui",
  "loadMore": "Charger plus",
  "editing": "Édition",
  "loading": "Chargement...",
  "restoreInitial": "Rétablir l'initial",
  "applyToAllPeriods": "Appliquer à toutes les périodes",
  "applyToSpecificPeriods": "Appliquer à des périodes spécifiques",
  "clearAll": "Tout supprimer",
  "none": "Aucune",
  "toOpenInANewTab": "pour ouvrir un nouvel onglet",
  "noEntitiesFound": "Aucun {entities} trouvé",
  "byName": "par nom",
  "byNameAndRef": "par nom et reference",
  "notFound": "{entity} non trouvé(e)",
  "notFoundExplanation": "ID {id} de {entity} n'existe(e) pas ou a été supprimé(e)",
  "nMore": "{count} autre | {count} autres",
  "overdue": "En retard",
  "more": "Plus",
  "euro": "Euro, €",
  "greaterThan": "Supérieur à",
  "between": "Entre",
  "lessThan": "Inférieur à",
  "enter": "Entrer",
  "advancedConfiguration": "Configuration avancée",
  "internalCustomerForecastTooltip": "Les prévisions ne s'appliquent pas aux clients internes. Seule la demande ferme est utilisée pour le calcul.",
  "familyConstraints": "Contraintes de la famille",
  "familyTagName": "Tag de la famille",
  "familyMOQ": "Qte min de la famille",
  "familyUnit": "Unité de la famille",
  "familyMOQExplanation": "Ce MOQ est appliqué au groupe de produits appartenant à cette famille",
  "familyLotSize": "Taille de lot de la famille",
  "familyLotSizeExplanation": "Cette taille de lot est appliquée au \"niveau familial\" sur tous les produits partageant le même composant de produit",
  "tags": "Tags | Tag | Tags",
  "tagCategories": "Catégories de tags | Catégorie de tag | Catégories de tags",
  "usedToGroupOrders": "Regroupe les commandes",
  "createTagCategory": "Créer une catégorie de tags",
  "addToNewTagCategory": "Ajouter à une nouvelle catégorie de tags",
  "editTagCategory": "Modifier la catégorie de tags",
  "tagsSelected": "{count} tag sélectionné | {count} tag sélectionné | {count} tags sélectionnés",
  "tagCategoryName": "Nom de la catégorie",
  "tagCategoryExplanation1": "Les catégories de tags sont un système pour regrouper des tags ayant la même signification afin d'organiser et de classer les produits",
  "tagCategoryExplanation2": "par leurs attributs ou caractéristiques. Par exemple, vous pouvez avoir des catégories de tags comme \"Couleur\", \"Taille\" et \"Matériau\", chacune pouvant contenir plusieurs tags spécifiques. Ensuite, vous pouvez utiliser ces catégories dans les listes de produits pour obtenir des sélections plus précises.",
  "seeLess": "voir moins",
  "seeMore": "voir plus",
  "createCategory": "Créer une catégorie",
  "saveCategory": "Enregistrer la catégorie",
  "add": "Ajouter",
  "addToTagCategory": "Ajouter à la catégorie de tags",
  "unassignCategories": "Retirer des catégories",
  "addToANewCategory": "Ajouter à une nouvelle catégorie",
  "useToGroupOrders": "Utiliser pour regrouper les commandes",
  "confirmToAssignNTagsToCategory": "Êtes-vous sûr de vouloir ajouter ce tag à la catégorie \"{name}\" ? | Êtes-vous sûr de vouloir ajouter ces {count} tags à la catégorie \"{name}\" ?",
  "confirmToUnassignNTagsToCategory": "Êtes-vous sûr de vouloir retirer le tag de sa catégorie ? | Êtes-vous sûr de vouloir retirer ces {count} tags de leur(s) catégorie(s) ?",
  "unassign": "Retirer",
  "errorAssignmentTags": "Une erreur est survenue lors de l'assignation ou de la désassignation des tags à la catégorie",
  "successAssignmentTags": "{count} tags ont été ajoutés à la catégorie {name}",
  "successUnassignmentTags": "{count} tags ont été désassignés de leurs catégories",
  "successUseTagsToGroupOrders": "Les tags ont été mis à jour",
  "errorUseTagsToGroupOrders": "Une erreur est survenue lors de l'utilisation des tags sélectionnés pour regrouper les commandes",
  "confirmUseToGroupOrders": "Êtes-vous sûr de vouloir utiliser {count} tags sélectionnés pour regrouper la commande ?",
  "use": "Utiliser",
  "errorDeletingTags": "Une erreur est survenue lors de la suppression des tags sélectionnés",
  "successDeletingTags": "Les tags ont été supprimés",
  "deleteNTags": "Supprimer {count} tags ?",
  "theseTagsUsedInNProducts": "Ces tags sont utilisés dans {count} produits. Vous ne pourrez pas annuler cette action.",
  "tooltipProductDefaultSupplier": "Lors de la sélection d'un fournisseur par défaut, les commandes seront attribuées par défaut à ce fournisseur.",
  "defaultSupplier": "Fournisseur par défaut",
  "bomFetchError": "Une erreur s'est produite lors de la récupération des données de BOM",
  "configureColums": "Configurer les colonnes",
  "yourDefaultCompany": "Votre entreprise par défaut",
  "yourDefaultSite": "Votre site par défaut",
  "tagCategory": "Catégorie de tags",
  "quotas": {
    "createQuotaDisabledWarning": "Vous n'êtes pas autorisé à définir un fournisseur préféré et à créer de nouveaux quotas lorsqu'il existe des quotas permanents. Pour être autorisé à faire ces configurations, veuillez supprimer les quotas permanents",
    "createQuotas": "Créer des quotas",
    "defaultSupplierChanged": "Le fournisseur par défaut a été mis à jour",
    "defaultSupplierIntegratedWarning": "Cette valeur est intégrée à partir d'une source externe",
    "defaultSupplierNoAvailable": "Le fournisseur par défaut ne peut pas être modifié lorsque vous avez des quotas actifs",
    "defaultQuotaExistsWarning": "Vous ne pouvez pas créer de quotas lorsque un fournisseur par défaut est défini",
    "deleteQuotaWarningContent": "Si vous supprimez ces quotas, vous perdrez les configurations créées. Vous ne pourrez pas annuler cette action.",
    "deleteQuotaWarningTitle": "Voulez-vous supprimer ces quotas",
    "expired": "Expiré",
    "expiredQuotas": "Quotas expirés",
    "integratedQuotaWarning": "Les quotas sont intégrés à partir d'une source externe.",
    "noDefaultSupplier": "Aucun fournisseur par défaut",
    "permanent": "Quotas permanents",
    "setDefaultSupplier": "Définir le fournisseur par défaut",
    "setDefaultSupplierTooltip": "En sélectionnant un fournisseur préféré, les commandes seront attribuées par défaut à ce fournisseur.",
    "setQuotaForSuppliers": "Définir les quotas pour les fournisseurs",
    "updateQuotas": "Mettre à jour les quotas",
    "form": {
      "create": "Créer des quotas",
      "duration": "Durée",
      "durationRequired": "Veuillez sélectionner une durée",
      "permanentDisabledTooltip": "Vous ne pouvez pas définir des quotas comme permanents lorsqu'il existe des quotas avec durée.",
      "rangeAlreadyExists": "Vous ne pouvez pas sélectionner une plage de dates où des quotas existent déjà",
      "save": "Sauvegarder",
      "setAsPermanent": "Définir comme permanent",
      "totalQuotaWarningCurrent": "La somme des quotas actuellement est de",
      "totalQuotaWarningExpected": "La somme des valeurs doit être égale à"
    }
  },
  "productEditTabs": {
    "events": "Événements",
    "info": "Infos",
    "inventoryStrategies": "Stratégie d'inventaire",
    "quotas": "Quotas",
    "similarProducts": "Produits similaires",
    "suppliers": "Fournisseurs",
    "tags": "Tags",
    "bom": "Bom"
  },
  "from": "De",
  "to": "À",
  "selectDate": "Sélectionner la date",
  "click": "Clic",
  "thereAreOnlySitesWhereProductPresented": "Il n'y a que des sites où le produit actuel est présenté.",
  "switchBackTo": "Revenir à",
  "toNavigateThroughTheList": "Pour naviguer dans la liste",
  "searchByNameOrPasteRefs": "Recherchez par nom ou collez des références",
  "value": "Valeur",
  "value€": "Valeur, €",
  "count": "Nombre",
  "atNextDelivery": "À la prochaine livraison",
  "current": "Actuel",
  "last30days": "30 derniers jours",
  "last3months": "3 derniers mois",
  "last6months": "6 derniers mois",
  "last12months": "12 derniers mois",
  "last30d": "30 derniers j",
  "last3m": "3 derniers m",
  "last6m": "6 derniers m",
  "last12m": "12 derniers m",
  "next30days": "30 prochains jours",
  "next3months": "3 prochains mois",
  "next6months": "6 prochains mois",
  "next12months": "12 prochains mois",
  "next30d": "30 prochains j",
  "next3m": "3 prochains m",
  "next6m": "6 prochains m",
  "next12m": "12 prochains m",
  "dataSource": "Source de données",
  "formula": "Formule",
  "kpiType": "Type de KPI",
  "firmDemand": "Demande ferme",
  "dataMeasure": "Mesure des données",
  "measure": "Mesure",
  "forecastType": "Type de prévision",
  "ordersType": "Type de commandes",
  "ordersNoun": "Commandes",
  "min": "Min",
  "max": "Max",
  "trend": "Tendance",
  "ratio": "Ratio",
  "mape": "MAPE",
  "mae": "MAE",
  "fva": "FVA",
  "all": "Tout",
  "firm": "Ferme",
  "planned": "Planifié",
  "statistic": "Statistique",
  "pastPerformance": "Performance passée",
  "finalForecast": "Prévision finale",
  "externalForecast": "Prévision externe",
  "myForecast": "Ma prévision",
  "flowlityForecast": "Prévision Flowlity",
  "stockMin": "Stock minimum",
  "kpi": "KPI",
  "createKPI": "Créer un KPI",
  "thisKpiAlreadyAdded": "Ce KPI est déjà ajouté",
  "pastDemandValueSumDescription": "Demande passée en € pour {for}",
  "pastDemandValueTrendDescription": "Compare la demande passée (en €) entre les périodes {period1} et {period2}",
  "pastDemandQtySumDescription": "Demande passée en unité par défaut pour {for}",
  "pastDemandQtyTrendDescription": "Compare la quantité de demande passée (en unités par défaut) entre les périodes {period1} et {period2}",
  "daysShort": "j",
  "deleteKPI": "Supprimer le KPI",
  "deleteKPIMsg": "Lorsque vous supprimez, ce KPI sera retiré de toutes les vues et sites de votre entreprise, pour tous les utilisateurs. Cette action est irréversible.",
  "kpiDeleted": "KPI supprimé",
  "allOrders": "Toutes les commandes",
  "firmOrders": "Commandes fermes",
  "plannedOrders": "Commandes planifiées",
  "inventory": "Inventaire",
  "reference": "Référence",
  "nextDelivery": "Prochaine livraison",
  "simulated": "Simulé",
  "trendGapsLast30d": "[J-60; J-31] et [J-30; J-1]",
  "trendGapsLast3m": "[M-6; M-4] et [M-3; M-1]",
  "trendGapsLast6m": "[M-12; M-7] et [M-6; M-1]",
  "trendGapsLast12m": "[M-24; M-13] et [M-12; M-1]",
  "trendGapsNext30d": "[J-30; J-1] et [J+1; J+30]",
  "trendGapsNext3m": "[M-3; M-1] et [M+1; M+3]",
  "trendGapsNext6m": "[M-6; M-1] et [M+1; M+6]",
  "trendGapsNext12m": "[M-12; M-1] et [M+1; M+12]",
  "createKPIDescription": "Vous pouvez créer un KPI qui sera affiché sous forme de colonne dans la liste des produits. Ce KPI sera disponible dans toutes les vues et sur tous les sites de votre entreprise, pour tous les utilisateurs.",
  "pastDemandExplanation": "Demande passée (en {unit}) pour {for}{formula}",
  "pastDemandTrendExplanation": "Compare la demande passée (en {unit}) entre les périodes {gaps}",
  "firmDemandExplanation": "Commandes validées (en {unit}) reçues de vos clients externes ou internes (commandes de transfert, commandes de production, etc.) pour {for}",
  "finalForecastExplanation": "Prévision finale (en {unit}) pour {for}",
  "finalForecastTrendExplanation": "Compare la prévision finale (en {unit}) entre les périodes {gaps}",
  "externalForecastExplanation": "Prévision externe (en {unit}) pour {for}",
  "externalForecastTrendExplanation": "Compare la prévision externe (en {unit}) entre les périodes {gaps}",
  "myForecastExplanation": "Ma prévision (en {unit}) pour {for}",
  "myForecastTrendExplanation": "Compare ma prévision (en {unit}) entre les périodes {gaps}",
  "flowlityForecastExplanation": "Prévision Flowlity (en {unit}) pour {for}",
  "flowlityForecastTrendExplanation": "Compare la prévision Flowlity (en {unit}) entre les périodes {gaps}",
  "maeExplanation": "Erreur absolue entre la prédiction Flowlity et la demande passée au cours des {count} derniers jours : ABS(demande - prévision) en quantité",
  "mapeExplanation": "Erreur moyenne absolue en pourcentage entre la prédiction Flowlity et la demande passée au cours des {count} derniers jours : ABS(demande - prévision)/demande (en %)",
  "forecastValueAddedExplanation": "Valeur ajoutée par la prédiction flowlity par rapport à un modèle statistique (Moyenne Mobile des trois derniers mois). Lorsque la valeur est positive la prévision flowlity est meilleure de x%",
  "ordersCountExplanation": "Nombre de {dataset} pour {for}",
  "ordersValueExplanation": "Somme des valeurs de {dataset} pour {for}",
  "ordersQuantityExplanation": "Somme des quantités (en unité par défaut) de {dataset} pour {for}",
  "inventoryExplanation": "Niveau d'inventaire (en {unit}), {for}{formula}",
  "inventoryTrendExplanation": "Compare le niveau d'inventaire (en {unit}) entre les périodes {gaps}",
  "stockCoverageExplanation": "Couverture de stock (en jours), {for}{formula}",
  "stockMinExplanation": "Couverture de stock (en {unit}), {for}{formula}",
  "zeroStockDaysExplanation": "Pourcentage moyen de jours sans stock sur {for}",
  "zeroStockDaysTrendExplanation": "Compare le pourcentage moyen de jours sans stock entre les périodes {gaps}",
  "minimum": "Minimum",
  "maximum": "Maximum",
  "productDescription": "Description du produit",
  "leadTime": "Délai de livraison",
  "defaultUnitName": "Unité par défaut",
  "stockCoverageNext1YAvgTooltip": "Couverture de stock en jours - moyenne pour l'année prochaine",
  "stockReduction": "Réduction du stock %",
  "stockChangeTooltip": "Changement en % du niveau de stock par rapport à la base",
  "productName": "Nom du produit",
  "defaultUnit": "Unité par défaut",
  "endOfLife": "Fin de vie",
  "supplyBufferPolicyTooltip": "Stratégie appliquée pour couvrir l'incertitude fournisseur : buffer en temps ou en stock",
  "reviewDemand": "Revue de la Demande",
  "reviewPlanning": "Revue du Planning",
  "inventoryStrategyNoun": "Stratégie de stock",
  "similarProducts": "Produits similaires",
  "fixingRecommendationsDate": "Recommandations figées",
  "until": "Jusqu'à",
  "promotions": "Promotions",
  "forecastSource": "Source de prédiction",
  "demandPredictability": "Prédictibilité",
  "demandPredictabilitySubtitle": "de la demande",
  "demandPredictabilityTooltip": "La prédictibilité de la demande est la qualité des données de demande passées utilisées pour générer la prévision",
  "demandPredictabilityNoTooltip": "Pas de demande passée, une prévision ne peut pas être générée",
  "demandPredictabilityLowTooltip": "La demande passée est limitée et ne permet pas de générer les meilleures prévisions",
  "demandPredictabilityMediumTooltip": "Flowlity génère les meilleures prévisions possibles, mais la demande peut avoir un comportement erratique",
  "demandPredictabilityHighTooltip": "Flowlity génère les meilleures prévisions possibles",
  "demandType": "Type de demande",
  "bufferLevelTooltip": "Facteur qui influence le niveau de stock minimum : plus le niveau de stock sécurité est élevé, moins vous acceptez de risque sur le produit, et plus le niveau de stock minimum est élevé",
  "firmOrdersQtyTooltip": "Quantité en commandes d'approvisionnement fermes",
  "nextDeliveryDate": "Date de la prochaine livraison",
  "shelfLife": "Durée de vie",
  "withComponents": "Avec composants",
  "alerts": "Alertes",
  "next1YAvg": "moy. futur 1 an",
  "zeroStockDaysNext1YearTooltip": "Pourcentage moyen de jours sans stock sur l'année prochaine",
  "flowlityAi": "IA Flowlity",
  "bufferPolicy": "Politique de buffer",
  "initialValue": "Valeur initiale",
  "tagName": "Nom de tag"
}
