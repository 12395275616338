import { IDemandSource, IPlanningSource } from "@/views/forecast/typings";
import { IOrderLinesListColumnKey, IOrdersListColumnKey } from "@/views/orders/typings";
import { ISite } from "@/typings/models/v2/site";
import { FEATURE_FLAG } from "@/typings/models/v2/features";
import { IProductColumnKey } from "@/shared/tables/columns/columns.types";
import { FlowlityDateUnit } from "@/utils/date";

export enum ITagStrategyEnum {
  STRATEGY_OR = "strategy_or",
  STRATEGY_AND = "strategy_and",
}

export enum UserLocaleEnum {
  FR = "fr",
  EN = "en",
  RU = "ru",
}

export interface IUser {
  id: number;
  name: string;
  isActive: boolean;
  email: string;
  locale: UserLocaleEnum;
  company: {
    id: number;
  };
  sites: ISite[];
  defaultSite?: {
    id: number;
  };
  isAdmin: boolean;
  isSuperAdmin: boolean;
  simulationColumnsSetting: IProductColumnKey[];
  demandDrawerSourcesSetting: IDemandSource[];
  planningDrawerSourcesSetting: IPlanningSource[];
  ordersColumnsSetting: IOrdersListColumnKey[];
  orderLinesColumnsSetting: IOrderLinesListColumnKey[];
  orderDetailsColumnsSetting: IOrderLinesListColumnKey[];
  sitesDisplaySetting: number[];
  alertsCriticalityColumnsSettings: number[];
  globalPerPage: number;
  canAccess: (rules: Array<string>) => boolean;
  tagStrategy: ITagStrategyEnum;
  permissions: string[];
  features: FEATURE_FLAG[];
  hasTacticalWithEditionMode: boolean;
  hasTacticalWithSimulationMode: boolean;
  demandTimeBucketSetting: FlowlityDateUnit;
  demandStartDateRelativeSetting: number;
  demandEndDateRelativeSetting: number;
  planningTimeBucketSetting: FlowlityDateUnit;
  planningStartDateRelativeSetting: number;
  planningEndDateRelativeSetting: number;
}
